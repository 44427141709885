<template>
  <div :class="['relative', isActive ? 'w-72 h-96' : 'w-32 h-32']">
    <div
      id="video-bubble"
      @click="toggleActiveClass"
      :class="[
        'fixed bottom-8 right-8 cursor-pointer z-20 overflow-hidden',
        isActive
          ? 'w-72 h-96 rounded-md border-4 border-gray-800'
          : 'w-32 h-32 rounded-full border-4 border-custom-pink',
      ]"
      :style="style"
    >
      <video
        id="info-video"
        autoplay
        :muted="isActive ? false : true"
        :loop="isActive ? false : true"
        controlsList="nodownload"
        class="absolute top-0 left-0 object-cover object-top w-full h-full"
        :src="videoSrc"
        @canplay="isReady = true"
      ></video>
      <img
        v-if="!isActive || (isActive && !isReady)"
        src="restimuli_gif.gif"
        alt="placeholder"
        class="absolute top-0 left-0 w-full h-full object-cover object-top"
      />
      <div
        v-if="buffering"
        class="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
      >
        <p class="text-white">Buffering...</p>
      </div>
    </div>
    <button
      v-if="isActive"
      id="close-button"
      @click="hideVideo"
      class="fixed right-5 bottom-5 w-8 h-8 bg-custom-pink rounded-full cursor-pointer z-20"
    >
      <svg
        viewBox="0 0 20 20"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-3.5 h-3.5 text-white"
      >
        <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    style: Object,
  },
  data() {
    return {
      isActive: false,
      videoSrc: '',
      isReady: false,
      buffering: false,
    };
  },
  methods: {
    toggleActiveClass() {
      if (this.isActive) {
        this.isActive = false;
        this.videoSrc = '';
      } else {
        this.isActive = true;
        this.videoSrc = 'restimuli_pres_compr.mp4';
        this.isReady = false;
      }
    },
    hideVideo() {
      this.isActive = false;
      this.videoSrc = '';
    },
  },
  watch: {
    isActive(newVal) {
      if (!this.isReady && newVal) {
        this.buffering = true;
        setTimeout(() => {
          this.buffering = false;
        }, 2000);
      }
    },
  },
};
</script>
