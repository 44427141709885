export const servicesByCompany = [
  {
    "Offering name": "Jcovden",
    "Company Name": "Janssen",
    "Description": "JCOVDEN is a vaccine used for preventing COVID-19 caused by the SARS-CoV-2 virus.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Belgium",
    "Country of Manufacture": "Made in Belgium",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.janssen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "How to Contact (URL or email)": "https://static.janssen-emea.com/sites/default/files/EMEA/PIL/EMEA-PL-0114.pdf",
    "Linkedin": "https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
    "Twitter": "https://twitter.com/JanssenEMEA",
    "Blog": "https://www.janssen.com/blogs"
  },
  {
    "Offering name": "Imodium",
    "Company Name": "Janssen",
    "Description": "Almost everyone has diarrhoea from time to time, while some people suffer more frequently. Whether your upset stomach is down to food, stress or a virus, IMODIUM® is here to help.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Belgium",
    "Country of Manufacture": "Made in Belgium",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.janssen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "How to Contact (URL or email)": "https://www.imodium.co.uk/imodium-heritage",
    "Linkedin": "https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
    "Twitter": "https://twitter.com/JanssenEMEA",
    "Blog": "https://www.janssen.com/blogs"
  },
  {
    "Offering name": "Ponvory",
    "Company Name": "Janssen",
    "Description": "PONVORY®, A ONCE-DAILY PILL FOR ADULTS WITH RELAPSING MS",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Belgium",
    "Country of Manufacture": "Made in Belgium",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.janssen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "How to Contact (URL or email)": "https://www.ponvory.com/",
    "Linkedin": "https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
    "Twitter": "https://twitter.com/JanssenEMEA",
    "Blog": "https://www.janssen.com/blogs"
  },
  {
    "Offering name": "Opsumit",
    "Company Name": "Janssen",
    "Description": "OPSUMIT® is a prescription medicine used to treat pulmonary arterial hypertension (PAH, WHO Group 1). PAH is high blood pressure in the arteries of your lungs.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Belgium",
    "Country of Manufacture": "Made in Belgium",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.janssen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "How to Contact (URL or email)": "https://opsumit.com/",
    "Linkedin": "https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
    "Twitter": "https://twitter.com/JanssenEMEA",
    "Blog": "https://www.janssen.com/blogs"
  },
  {
    "Offering name": "Aspirin",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.aspirin.com/",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "Talcid",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.bayer.com/en/products/products-from-A-to-Z?title=talcid&field_product_context_target_id%5B%5D=1066&field_country_value_1=All",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "Medrad Avanta",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.radiology.bayer.ca/en/products/medrad-avanta",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "Eylea",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.eylea.co.uk/eylea/homepage-hcp",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "Aspirin",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.aspirin.com/",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "Talcid",
    "Company Name": "Bayer",
    "Description": "Speak with our team to claim Bayer's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Switzerland",
    "Country of Manufacture": "Made in Switzerland",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bayer.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to Contact (URL or email)": "https://www.bayer.com/en/products/products-from-A-to-Z?title=talcid&field_product_context_target_id%5B%5D=1066&field_country_value_1=All",
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Offering name": "AirDuo Digihaler",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.digihaler.com/",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "ProAir Digihaler",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.digihaler.com/inhalers/proair",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "Naloxone hydrochloride nasal",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.tevanaloxone.com/",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "Alprazolam",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.tevausa.com/products/alprazolamtabletsuspciv",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "AirDuo Digihaler",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.digihaler.com/",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "ProAir Digihaler",
    "Company Name": "Teva",
    "Description": "Speak with our team to claim Teva's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Israel",
    "Country of Manufacture": "Made in Israel",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tevapharm.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to Contact (URL or email)": "https://www.digihaler.com/inhalers/proair",
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Offering name": "OMEZ",
    "Company Name": "Dr. Reddy's",
    "Description": "Omez 20Mg tablet is an anti-ulcer medicine used to treat conditions where the stomach produces too much acid.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in India",
    "Country of Manufacture": "Made in India",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.drreddys.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "How to Contact (URL or email)": "https://www.drreddys.com/media/109870/omeprazole-20mg-pil.pdf",
    "Linkedin": "https://www.linkedin.com/company/dr--reddys-laboratories/?trk=nav_account_sub_nav_company_admin",
    "Twitter": "https://twitter.com/drreddys",
    "Blog": "https://www.drreddys.com/#sustainability"
  },
  {
    "Offering name": "Nise",
    "Company Name": "Dr. Reddy's",
    "Description": "Nise Tablet is used in the treatment of Pain relief, fever.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in India",
    "Country of Manufacture": "Made in India",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.drreddys.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "How to Contact (URL or email)": "https://www.drreddys.com/media/165541/nise-d-spray.pdf",
    "Linkedin": "https://www.linkedin.com/company/dr--reddys-laboratories/?trk=nav_account_sub_nav_company_admin",
    "Twitter": "https://twitter.com/drreddys",
    "Blog": "https://www.drreddys.com/#sustainability"
  },
  {
    "Offering name": "Stamlo",
    "Company Name": "Dr. Reddy's",
    "Description": "Stamlo 2.5mg Tab contains a powerful ingredient amlodipine which helps to control high blood pressure and this reduces your risk of having a heart attack or a stroke.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in India",
    "Country of Manufacture": "Made in India",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.drreddys.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "How to Contact (URL or email)": "https://www.drreddys.com/india/portfolio/therapy-areas/",
    "Linkedin": "https://www.linkedin.com/company/dr--reddys-laboratories/?trk=nav_account_sub_nav_company_admin",
    "Twitter": "https://twitter.com/drreddys",
    "Blog": "https://www.drreddys.com/#sustainability"
  },
  {
    "Offering name": "Razo",
    "Company Name": "Dr. Reddy's",
    "Description": "Razo 20 Tablet is used in the treatment of Gastroesophageal reflux disease (Acid reflux),Peptic ulcer disease.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in India",
    "Country of Manufacture": "Made in India",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.drreddys.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "How to Contact (URL or email)": "https://www.drreddys.com/india/portfolio/top-brands/",
    "Linkedin": "https://www.linkedin.com/company/dr--reddys-laboratories/?trk=nav_account_sub_nav_company_admin",
    "Twitter": "https://twitter.com/drreddys",
    "Blog": "https://www.drreddys.com/#sustainability"
  },
  {
    "Offering name": "Audiovit",
    "Company Name": "Salvat",
    "Description": "Audiovit Food Supplement 60 Capsules, is a powerful antioxidant compound that promotes auditory function and regulates sleep.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Spain",
    "Country of Manufacture": "Made in Spain",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://svt.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "How to Contact (URL or email)": "https://svt.com/en/portfolio-item/audiovit/",
    "Linkedin": "https://www.linkedin.com/company/salvat/",
    "Twitter": "https://twitter.com/salvatpharma?lang=en",
    "Blog": "https://svt.com/en/commitment/"
  },
  {
    "Offering name": "Cetraflux",
    "Company Name": "Salvat",
    "Description": "Cetraflux 3 mg/ml eye drop solution is indicated for the local treatment of the following infections caused by strains sensitive to ciprofloxacin, in neonates (0-27 days), infants (aged 28 days to 23 months), children (aged 2-11 years), adolescents (aged 12-16 years) and adults:",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Spain",
    "Country of Manufacture": "Made in Spain",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://svt.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "How to Contact (URL or email)": "https://svt.com/en/portfolio-item/cetraflux/",
    "Linkedin": "https://www.linkedin.com/company/salvat/",
    "Twitter": "https://twitter.com/salvatpharma?lang=en",
    "Blog": "https://svt.com/en/commitment/"
  },
  {
    "Offering name": "Megalevure",
    "Company Name": "Salvat",
    "Description": "Antibiotic-associated diarrhea, traveler’s diarrhea and viral diarrhea (such as that caused by rotavirus among infants and young children) disrupts the balance of intestinal flora. These bacteria are vital for the intestines to work correctly. A bacterial infection, a virus, a change of diet or an antibiotic treatment could also lead to dysbacteriosis (a change to the normal microbial ecosystem).\nMegaLevure helps to rebalance the gut flora, while its vitamin D3 content enhances immunity.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Spain",
    "Country of Manufacture": "Made in Spain",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://svt.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "How to Contact (URL or email)": "https://svt.com/en/portfolio-item/megalevure/",
    "Linkedin": "https://www.linkedin.com/company/salvat/",
    "Twitter": "https://twitter.com/salvatpharma?lang=en",
    "Blog": "https://svt.com/en/commitment/"
  },
  {
    "Offering name": "Nasolina",
    "Company Name": "Salvat",
    "Description": "Symptomatic relief of nasal descongestion associated with common cold, rhinitis and sinusitis.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Spain",
    "Country of Manufacture": "Made in Spain",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://svt.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "How to Contact (URL or email)": "https://svt.com/en/portfolio-item/nasolina/",
    "Linkedin": "https://www.linkedin.com/company/salvat/",
    "Twitter": "https://twitter.com/salvatpharma?lang=en",
    "Blog": "https://svt.com/en/commitment/"
  },
  {
    "Offering name": "Sodium Hydroxide Solution",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add products with their credentials to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/product-finder/chloralkali-products/sodium-hydroxide-solution#:~:text=Colourless%2C%20odourless%20liquid%2C%20free%20of,fat%20and%20protein%20based%20deposits.",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Hydrochloric Acid Solution",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add products with their credentials to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/product-finder/hydrochloric-acid-solution-technical-grade#:~:text=Colourless%20or%20slightly%20yellowish%20air,also%20ideal%20for%20steel%20pickling.",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Sodium Hypochlorite",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add products with their credentials to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/product-finder/chloralkali-products/sodium-hypochlorite-solution",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Adhesives and binders",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add products with their credentials to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/download/42/borsodchem-binders-brochure",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Sodium Hydroxide Solution",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add any product donation programmes to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/product-finder/chloralkali-products/sodium-hydroxide-solution#:~:text=Colourless%2C%20odourless%20liquid%2C%20free%20of,fat%20and%20protein%20based%20deposits.",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Hydrochloric Acid Solution",
    "Company Name": "BorsodChem",
    "Description": "Speak with our team to claim BorsodChem's profile and add any product donation programmes to this storefront.",
    "Category": "Chemicals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Hungary",
    "Country of Manufacture": "Made in Hungary",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://borsodchem.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to Contact (URL or email)": "https://borsodchem.com/en/product-finder/hydrochloric-acid-solution-technical-grade#:~:text=Colourless%20or%20slightly%20yellowish%20air,also%20ideal%20for%20steel%20pickling.",
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Offering name": "Ambirix",
    "Company Name": "GSK",
    "Description": "hepatitis A (inactivated) and hepatitis B (rDNA) (HAB) vaccine (adsorbed)",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.gsk.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "How to Contact (URL or email)": "https://public.gsk.co.uk/products/ambirix.html",
    "Linkedin": "https://www.linkedin.com/company/glaxosmithkline/",
    "Twitter": "https://twitter.com/GSK",
    "Blog": "https://www.gsk.com/en-gb/responsibility/"
  },
  {
    "Offering name": "Anoro Ellipta",
    "Company Name": "GSK",
    "Description": "COPD MAKES IT HARD TO BREATHE\nAsk for better breathing. Ask your doctor about once-daily ANORO.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.gsk.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "How to Contact (URL or email)": "https://www.anoro.com/",
    "Linkedin": "https://www.linkedin.com/company/glaxosmithkline/",
    "Twitter": "https://twitter.com/GSK",
    "Blog": "https://www.gsk.com/en-gb/responsibility/"
  },
  {
    "Offering name": "Combivir",
    "Company Name": "GSK",
    "Description": "Combivir is indicated in antiretroviral combination therapy for the treatment of Human. Immunodeficiency Virus (HIV) infection.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.gsk.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "How to Contact (URL or email)": "https://gskpro.com/content/dam/global/hcpportal/en_US/Prescribing_Information/Combivir/pdf/COMBIVIR.PDF",
    "Linkedin": "https://www.linkedin.com/company/glaxosmithkline/",
    "Twitter": "https://twitter.com/GSK",
    "Blog": "https://www.gsk.com/en-gb/responsibility/"
  },
  {
    "Offering name": "Dovato",
    "Company Name": "GSK",
    "Description": "DOVATO is a complete prescription regimen to treat HIV-1 in adults who have not received HIV-1 medicines in the past or to replace their current HIV-1 medicines when their doctor determines they meet certain requirements.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.gsk.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "How to Contact (URL or email)": "https://www.dovato.com/",
    "Linkedin": "https://www.linkedin.com/company/glaxosmithkline/",
    "Twitter": "https://twitter.com/GSK",
    "Blog": "https://www.gsk.com/en-gb/responsibility/"
  },
  {
    "Offering name": "Acetic acid",
    "Company Name": "Ineos",
    "Description": "Acetic acid is one of the most versatile organic chemicals manufactured today with applications in almost every industry. It is a significant chemical reagent and industrial chemical used in the production of soft drink bottles (PET), manufacture of vinyl acetate monomer (VAM) and purified terephthalic acid (PTA). It is used to produce many synthetic fibers and fabrics, as well as a host of household, food, and other applications that are essential to everyday life.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ineos.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "How to Contact (URL or email)": "https://www.ineos.com/industry/products/chemicals/acetyls/",
    "Linkedin": "https://www.linkedin.com/company/airtable/",
    "Twitter": "https://twitter.com/INEOS",
    "Blog": "https://www.ineos.com/sustainability/"
  },
  {
    "Offering name": "PTA",
    "Company Name": "Ineos",
    "Description": "Hydrocarbons that are in a ring formation instead of a linear formation are known as aromatics, as shown in the diagram below.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ineos.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "How to Contact (URL or email)": "https://www.ineos.com/industry/products/chemicals/aromatics/",
    "Linkedin": "https://www.linkedin.com/company/airtable/",
    "Twitter": "https://twitter.com/INEOS",
    "Blog": "https://www.ineos.com/sustainability/"
  },
  {
    "Offering name": "Vinyl Chloride Monomer",
    "Company Name": "Ineos",
    "Description": "Vinyl chlorine monomer (‘VCM’) is the primary raw material used in the manufacture of polyvinyl chloride (‘PVC’) resins and is produced by the reaction of ethylene and chlorine, which produces EDC that is then transformed into VCM by thermal cracking. PVC resin is then formed by the polymerization of VCM. PVC is made as two main product types, each subdivided into a range of grades to meet the requirements of specific applications. PVC is produced according to two types of polymerisation techniques: suspension PVC and emulsion PVC.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ineos.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "How to Contact (URL or email)": "https://www.ineos.com/industry/products/polymers/pvc/",
    "Linkedin": "https://www.linkedin.com/company/airtable/",
    "Twitter": "https://twitter.com/INEOS",
    "Blog": "https://www.ineos.com/sustainability/"
  },
  {
    "Offering name": "Polyalphaolefins",
    "Company Name": "Ineos",
    "Description": "INEOS Oligomers is the world's largest merchant marketer of polyalphaolefins (PAO) synthetic fluids marketed under the trade name Durasyn.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "Made in United Kingdom",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ineos.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "How to Contact (URL or email)": "https://www.ineos.com/businesses/ineos-oligomers/products/durasyn-polyalphaolefin-pao/",
    "Linkedin": "https://www.linkedin.com/company/airtable/",
    "Twitter": "https://twitter.com/INEOS",
    "Blog": "https://www.ineos.com/sustainability/"
  },
  {
    "Offering name": "XprESS ENT",
    "Company Name": "Stryker",
    "Description": "The XprESS ENT dilation system delivers a combination of control and versatility for sinus and Eustachian tube balloon dilation.",
    "Category": "Medical Devices",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.stryker.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "How to Contact (URL or email)": "https://www.stryker.com/gb/en/ent/products/xpress.html",
    "Linkedin": "https://www.linkedin.com/company/stryker/",
    "Twitter": "https://twitter.com/StrykerComm",
    "Blog": "https://www.stryker.com/us/en/about/corporate-responsibility.html"
  },
  {
    "Offering name": "ClariFix cryotherapy system",
    "Company Name": "Stryker",
    "Description": "Don’t manage it. Fix it! ClariFix is a minimally invasive solution that allows you to get to the root of chronic rhinitis. The ClariFix device uses the properties of cryotherapy to treat the overactive nerves that cause rhinitis symptoms. It is a handheld device that provides focal, controlled freezing of the target tissue.",
    "Category": "Medical Devices",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.stryker.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "How to Contact (URL or email)": "https://www.stryker.com/gb/en/ent/products/clarifix.html",
    "Linkedin": "https://www.linkedin.com/company/stryker/",
    "Twitter": "https://twitter.com/StrykerComm",
    "Blog": "https://www.stryker.com/us/en/about/corporate-responsibility.html"
  },
  {
    "Offering name": "NasoPore bioresorbable\nnasal dressing",
    "Company Name": "Stryker",
    "Description": "Nasal dressings providing the hemostasis, support and stability your patients and procedures require.",
    "Category": "Medical Devices",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.stryker.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "How to Contact (URL or email)": "https://www.stryker.com/us/en/nse/products/nasopore-bioresorbable-nasal-dressing.html",
    "Linkedin": "https://www.linkedin.com/company/stryker/",
    "Twitter": "https://twitter.com/StrykerComm",
    "Blog": "https://www.stryker.com/us/en/about/corporate-responsibility.html"
  },
  {
    "Offering name": "MiniFESS sphenoid\nseeker",
    "Company Name": "Stryker",
    "Description": "MiniFESS provides you with a range of forceps and surgical instruments. Our instruments are designed for easy use, reliability, versatility to expand the procedures which you can confidently perform in your OR or in your ambulatory setting.",
    "Category": "Medical Devices",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.stryker.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "How to Contact (URL or email)": "https://www.stryker.com/gb/en/ent/products/Minifess.html",
    "Linkedin": "https://www.linkedin.com/company/stryker/",
    "Twitter": "https://twitter.com/StrykerComm",
    "Blog": "https://www.stryker.com/us/en/about/corporate-responsibility.html"
  },
  {
    "Offering name": "BioGaia Protectis baby drops",
    "Company Name": "BioGaia",
    "Description": "BioGaia Protectis Baby Drops is a high-quality probiotic food supplement. Trusted by parents everywhere, our multi-award-winning probiotic drops provide your baby with good bacteria. Talk about giving your child a great start in life!",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Sweden",
    "Country of Manufacture": "Made in Sweden",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biogaia.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685115920/orglogos/biogaia-logo_i4hdlb.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685115334/orglogos/biogaia_bjfako.png",
    "How to Contact (URL or email)": "https://biogaia.co.uk/products/protectis-baby-drops",
    "Linkedin": "https://www.linkedin.com/company/biogaia-ab/",
    "Twitter": "https://twitter.com/BioGaiaBellies",
    "Blog": "https://biogaia.co.uk/pages/sustainability"
  },
  {
    "Offering name": "BioGaia Protectis ORS",
    "Company Name": "BioGaia",
    "Description": "The patented lactic acid bacterium Limosilactobacillus reuteri (formerly known as Lactobacillus reuteri) Protectis helps the good microorganisms keep a natural balance in the gut.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Sweden",
    "Country of Manufacture": "Made in Sweden",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biogaia.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685115920/orglogos/biogaia-logo_i4hdlb.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685115334/orglogos/biogaia_bjfako.png",
    "How to Contact (URL or email)": "https://www.biogaia.com/product/biogaia-protectis-ors/",
    "Linkedin": "https://www.linkedin.com/company/biogaia-ab/",
    "Twitter": "https://twitter.com/BioGaiaBellies",
    "Blog": "https://biogaia.co.uk/pages/sustainability"
  },
  {
    "Offering name": "BioGaia Prodentis KIDS lozenges",
    "Company Name": "BioGaia",
    "Description": "BioGaia Prodentis KIDS lozenges is a food supplement for oral health containing the patented lactic acid bacterium Limosilactobacillus reuteri (formerly known as Lactobacillus reuteri) Prodentis (a strain combination of L. reuteri DSM 17938 and L. reuteri ATCC PTA 5289) that helps the good microorganisms keep a natural balance in the mouth. Suitable children over three years of age.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Sweden",
    "Country of Manufacture": "Made in Sweden",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biogaia.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685115920/orglogos/biogaia-logo_i4hdlb.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685115334/orglogos/biogaia_bjfako.png",
    "How to Contact (URL or email)": "https://www.biogaia.com/product/biogaia-prodentis-kids-lozenges/",
    "Linkedin": "https://www.linkedin.com/company/biogaia-ab/",
    "Twitter": "https://twitter.com/BioGaiaBellies",
    "Blog": "https://biogaia.co.uk/pages/sustainability"
  },
  {
    "Offering name": "BioGaia Osfortis",
    "Company Name": "BioGaia",
    "Description": "BioGaia Osfortis is a probiotic dietary supplement containing the patented lactic acid bacterium Limosilactobacillus reuteri (formerly known as Lactobacillus reuteri) Osfortis™ (L. reuteri ATCC PTA 6475) and vitamin D3. Vitamin D contributes to the maintenance of normal bones.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Sweden",
    "Country of Manufacture": "Made in Sweden",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biogaia.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685115920/orglogos/biogaia-logo_i4hdlb.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685115334/orglogos/biogaia_bjfako.png",
    "How to Contact (URL or email)": "https://www.biogaia.com/product/biogaia-osfortis/",
    "Linkedin": "https://www.linkedin.com/company/biogaia-ab/",
    "Twitter": "https://twitter.com/BioGaiaBellies",
    "Blog": "https://biogaia.co.uk/pages/sustainability"
  },
  {
    "Offering name": "Abetyl 250 Mg Tablet",
    "Company Name": "Abdi Ibrahim",
    "Description": "Important Note: Abetyl 250 Mg Tablet should only be used in conjunction with other therapies as prescribed by a qualified physician.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Turkey",
    "Country of Manufacture": "Made in Turkey",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.abdiibrahim.com.tr/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685219374/orglogos/abdi_gbfsmj.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685116416/orglogos/2010-award_nfmr02.png",
    "How to Contact (URL or email)": "https://www.abdiibrahim.com.tr/en/products/special-treatments",
    "Linkedin": "https://www.linkedin.com/company/abdi-ibrahim-pharmaceuticals?originalSubdomain=tr",
    "Twitter": "https://twitter.com/abdiibrahimilac",
    "Blog": "https://www.abdiibrahim.com.tr/en/sustainability/esg"
  },
  {
    "Offering name": "Acnelyse %0.05 Cream 20 Gr",
    "Company Name": "Abdi Ibrahim",
    "Description": "This excellent quality product has fantastic coverage and absorption. In general creams are around 5% better absorbed than gels making them stronger and we believe this is the most powerful Tretinoin available anywhere. Only to be used on pre-conditioned skin.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Turkey",
    "Country of Manufacture": "Made in Turkey",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.abdiibrahim.com.tr/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685219374/orglogos/abdi_gbfsmj.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685116416/orglogos/2010-award_nfmr02.png",
    "How to Contact (URL or email)": "https://www.abdiibrahim.com.tr/en/products/general-treatments",
    "Linkedin": "https://www.linkedin.com/company/abdi-ibrahim-pharmaceuticals?originalSubdomain=tr",
    "Twitter": "https://twitter.com/abdiibrahimilac",
    "Blog": "https://www.abdiibrahim.com.tr/en/sustainability/esg"
  },
  {
    "Offering name": "Citol 40 Mg Film Coated Tablet",
    "Company Name": "Abdi Ibrahim",
    "Description": "Each Film Tablet Contains 40 Mg Of Citalopram (49.96 Mg Of Citalopram Hydrobromide).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Turkey",
    "Country of Manufacture": "Made in Turkey",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.abdiibrahim.com.tr/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685219374/orglogos/abdi_gbfsmj.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685116416/orglogos/2010-award_nfmr02.png",
    "How to Contact (URL or email)": "https://www.abdiibrahim.com.tr/en/products/unpromoted-products",
    "Linkedin": "https://www.linkedin.com/company/abdi-ibrahim-pharmaceuticals?originalSubdomain=tr",
    "Twitter": "https://twitter.com/abdiibrahimilac",
    "Blog": "https://www.abdiibrahim.com.tr/en/sustainability/esg"
  },
  {
    "Offering name": "Coriva",
    "Company Name": "Abdi Ibrahim",
    "Description": "For comprehensive information on Coriva, including potential uses, risks, and side effects, please consult a healthcare professional.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Turkey",
    "Country of Manufacture": "Made in Turkey",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.abdiibrahim.com.tr/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685219374/orglogos/abdi_gbfsmj.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685116416/orglogos/2010-award_nfmr02.png",
    "How to Contact (URL or email)": "https://www.abdiibrahim.com.tr/Uploads/Product/prospektus/Coriva.pdf",
    "Linkedin": "https://www.linkedin.com/company/abdi-ibrahim-pharmaceuticals?originalSubdomain=tr",
    "Twitter": "https://twitter.com/abdiibrahimilac",
    "Blog": "https://www.abdiibrahim.com.tr/en/sustainability/esg"
  },
  {
    "Offering name": "CUBICIN® 500 mg Powder",
    "Company Name": "MSD",
    "Description": "Cubicin 500 mg powder for solution for injection or infusion",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.msd.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449705/orglogos/msd_r17ylr.png",
    "How to Contact (URL or email)": "https://www.msd-uk.com/products/",
    "Linkedin": "https://twitter.com/MSDInvents",
    "Twitter": "https://www.linkedin.com/company/msd-global/",
    "Blog": "https://www.msd.com/company-overview/esg/"
  },
  {
    "Offering name": "BRIDION® Solution for Injection",
    "Company Name": "MSD",
    "Description": "Bridion is a solution for injection that contains the active substance sugammadex (100 mg/ml).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.msd.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449705/orglogos/msd_r17ylr.png",
    "How to Contact (URL or email)": "https://www.msd-uk.com/products/",
    "Linkedin": "https://twitter.com/MSDInvents",
    "Twitter": "https://www.linkedin.com/company/msd-global/",
    "Blog": "https://www.msd.com/company-overview/esg/"
  },
  {
    "Offering name": "EMEND® Capsules",
    "Company Name": "MSD",
    "Description": "Emend 40 mg hard capsules is indicated for the prevention of postoperative nausea and vomiting (PONV) in adults.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.msd.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449705/orglogos/msd_r17ylr.png",
    "How to Contact (URL or email)": "https://www.msd-uk.com/products/",
    "Linkedin": "https://twitter.com/MSDInvents",
    "Twitter": "https://www.linkedin.com/company/msd-global/",
    "Blog": "https://www.msd.com/company-overview/esg/"
  },
  {
    "Offering name": "GARDASIL® 9 Suspension for Injection",
    "Company Name": "MSD",
    "Description": "Gardasil 9 is a suspension for injection available in vials or prefilled syringes.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.msd.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449705/orglogos/msd_r17ylr.png",
    "How to Contact (URL or email)": "https://www.msd-uk.com/products/",
    "Linkedin": "https://twitter.com/MSDInvents",
    "Twitter": "https://www.linkedin.com/company/msd-global/",
    "Blog": "https://www.msd.com/company-overview/esg/"
  },
  {
    "Offering name": "DELSTRIGO™",
    "Company Name": "Merck",
    "Description": "DELSTRIGO is a prescription medicine that is used without other HIV-1 medicines to treat HIV-1 infection in adults and\nchildren who weigh at least 77 pounds (35 kg).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.merckgroup.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449933/orglogos/merck_qy0edh.png",
    "How to Contact (URL or email)": "https://www.merck.com/product/usa/pi_circulars/d/delstrigo/delstrigo_ppi.pdf",
    "Linkedin": "https://twitter.com/merckgroup",
    "Twitter": "https://www.linkedin.com/company/merck-group/",
    "Blog": "https://www.merckgroup.com/en/sustainability.html"
  },
  {
    "Offering name": "FOLLISTIM® AQ Cartridge",
    "Company Name": "Merck",
    "Description": "FOLLISTIM AQ Cartridge is indicated for the induction of ovulation and pregnancy in anovulatory infertile women in whom the cause of infertility is functional and not due to primary ovarian failure.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.merckgroup.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449933/orglogos/merck_qy0edh.png",
    "How to Contact (URL or email)": "https://www.follistim.com/",
    "Linkedin": "https://twitter.com/merckgroup",
    "Twitter": "https://www.linkedin.com/company/merck-group/",
    "Blog": "https://www.merckgroup.com/en/sustainability.html"
  },
  {
    "Offering name": "KEYTRUDA®",
    "Company Name": "Merck",
    "Description": "TREATS 16 TYPES OF CANCER, INCLUDING CERTAIN EARLY-STAGE CANCERS.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.merckgroup.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449933/orglogos/merck_qy0edh.png",
    "How to Contact (URL or email)": "https://www.keytruda.com/",
    "Linkedin": "https://twitter.com/merckgroup",
    "Twitter": "https://www.linkedin.com/company/merck-group/",
    "Blog": "https://www.merckgroup.com/en/sustainability.html"
  },
  {
    "Offering name": "RECARBRIO™",
    "Company Name": "Merck",
    "Description": "RECARBRIO is indicated for the treatment of patients 18 years of age and older with hospital-acquired bacterial pneumonia and ventilator-associated bacterial pneumonia (HABP/VABP).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.merckgroup.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449933/orglogos/merck_qy0edh.png",
    "How to Contact (URL or email)": "https://www.merckconnect.com/recarbrio/dosing-administration/",
    "Linkedin": "https://twitter.com/merckgroup",
    "Twitter": "https://www.linkedin.com/company/merck-group/",
    "Blog": "https://www.merckgroup.com/en/sustainability.html"
  },
  {
    "Offering name": "Monoject™ SmarTip™",
    "Company Name": "Cardinal Health",
    "Description": "No needle means no needlestick: The Monoject™ SmarTip™ needleless vial access cannula is specifically designed to access rubber stopper medication vials without the risk of needlestick injury.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.cardinalhealth.com/en.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685450052/orglogos/Cardinal-Health-Emblem_w7z6oh.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449995/orglogos/cardinal_picu0g.png",
    "How to Contact (URL or email)": "https://www.cardinalhealth.com/en/product-solutions/medical/patient-care/sharps-safety/medication-preparation/monoject-smartip-needleless-vial-access-cannula.html#:~:text=No%20needle%20means%20no%20needlestick,the%20risk%20of%20needlestick%20injury.",
    "Linkedin": "https://www.linkedin.com/company/cardinal-health/",
    "Twitter": "https://twitter.com/cardinalhealth",
    "Blog": "https://www.cardinalhealth.com/en/about-us/environmental-social-governance.html"
  },
  {
    "Offering name": "Monoject™ blunt tip cannula",
    "Company Name": "Cardinal Health",
    "Description": "The Monoject™ blunt tip cannula is designed to provide needleless access to I.V. systems removing the presence of needles from the workplace environment.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.cardinalhealth.com/en.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685450052/orglogos/Cardinal-Health-Emblem_w7z6oh.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449995/orglogos/cardinal_picu0g.png",
    "How to Contact (URL or email)": "https://www.cardinalhealth.com/en/product-solutions/medical/patient-care/sharps-safety/medication-preparation/monoject-plastic-blunt-tip-cannula.html",
    "Linkedin": "https://www.linkedin.com/company/cardinal-health/",
    "Twitter": "https://twitter.com/cardinalhealth",
    "Blog": "https://www.cardinalhealth.com/en/about-us/environmental-social-governance.html"
  },
  {
    "Offering name": "Monoject™ Veterinary Insulin Needle",
    "Company Name": "Cardinal Health",
    "Description": "Monoject™ Veterinary insulin syringe combinations with prefixed needles are for U-40 insulin only and are available in soft packaging.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.cardinalhealth.com/en.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685450052/orglogos/Cardinal-Health-Emblem_w7z6oh.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449995/orglogos/cardinal_picu0g.png",
    "How to Contact (URL or email)": "https://www.cardinalhealth.com/en/product-solutions/medical/patient-care/sharps-safety/monoject-veterinary-supplies/vet-insulin-needles.html",
    "Linkedin": "https://www.linkedin.com/company/cardinal-health/",
    "Twitter": "https://twitter.com/cardinalhealth",
    "Blog": "https://www.cardinalhealth.com/en/about-us/environmental-social-governance.html"
  },
  {
    "Offering name": "Protexis® Latex",
    "Company Name": "Cardinal Health",
    "Description": "Sterile latex surgical gloves with nitrile coating deliver protection and fit.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.cardinalhealth.com/en.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685450052/orglogos/Cardinal-Health-Emblem_w7z6oh.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449995/orglogos/cardinal_picu0g.png",
    "How to Contact (URL or email)": "https://www.cardinalhealth.co.uk/en_gb/medical-products/surgical-gloves/latex-surgical-gloves/protexis-latex-classic-surgical-gloves.html",
    "Linkedin": "https://www.linkedin.com/company/cardinal-health/",
    "Twitter": "https://twitter.com/cardinalhealth",
    "Blog": "https://www.cardinalhealth.com/en/about-us/environmental-social-governance.html"
  },
  {
    "Offering name": "Alerid",
    "Company Name": "TLG Pharma",
    "Description": "Alerid Tablet is an anti-allergic medication, not a steroid. It relieves the symptoms of allergy.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://tlgcapital.com/tlg-pharma/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "How to Contact (URL or email)": "https://tlgcapital.com/tlg-pharma/pharmaceuticals/",
    "Linkedin": "https://www.linkedin.com/company/tlg-pharma/",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Lansec 30mg",
    "Company Name": "TLG Pharma",
    "Description": "Lansec 30 MG Capsule is an anti-ulcer medicine used to treat conditions where the stomach produces too much acid. Stomach and duodenal ulcers, gastroesophageal reflux disease (GERD), and Zollinger-Ellison syndrome are certain problems caused by high levels of stomach acid. It relieves stress ulcers and also acidity due to the intake of painkillers.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://tlgcapital.com/tlg-pharma/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "How to Contact (URL or email)": "https://tlgcapital.com/tlg-pharma/pharmaceuticals/",
    "Linkedin": "https://www.linkedin.com/company/tlg-pharma/",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Lumartem",
    "Company Name": "TLG Pharma",
    "Description": "Lumartem Tablet is a combination medicine used to treat non-severe malaria when certain other antimalarial medications become ineffective.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://tlgcapital.com/tlg-pharma/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "How to Contact (URL or email)": "https://tlgcapital.com/tlg-pharma/pharmaceuticals/",
    "Linkedin": "https://www.linkedin.com/company/tlg-pharma/",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Azee 500",
    "Company Name": "TLG Pharma",
    "Description": "Azee 500 MG Tablet is an antibiotic used to treat bacterial infections of the nose, throat, tonsils, ears, lungs, skin, etc.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United Kingdom",
    "Country of Manufacture": "",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://tlgcapital.com/tlg-pharma/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "How to Contact (URL or email)": "https://tlgcapital.com/tlg-pharma/pharmaceuticals/",
    "Linkedin": "https://www.linkedin.com/company/tlg-pharma/",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Reagent Test Strip McKesson Consult™",
    "Company Name": "McKesson",
    "Description": "The McKesson CONSULT® Urinalysis Reagent Strips are for the qualitative and semi-quantitative detection of one or more of the following analytes in urine: Ascorbic acid, Glucose, Bilirubin, Ketone (Acetoacetic acid), Specific Gravity, Blood, pH, Protein, Urobilinogen, Nitrite and Leukocytes.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Distributor",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.mckesson.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454735/orglogos/MCK_nz2dd9.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453916/orglogos/mkesson_emqrke.png",
    "How to Contact (URL or email)": "https://imgcdn.mckesson.com/CumulusWeb/Click_and_learn/Insert_121-10SG_Urine%2003-2015.pdf",
    "Linkedin": "https://www.linkedin.com/company/mckesson/",
    "Twitter": "https://twitter.com/McKesson",
    "Blog": "https://www.mckesson.com/About-McKesson/Impact/"
  },
  {
    "Offering name": "Gravity Feeding Bag Set with ENFit™",
    "Company Name": "McKesson",
    "Description": "Intended for use in administration of enteral nutrition, fluids or medication in place of an enteral feeding pump",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Distributor",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.mckesson.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454735/orglogos/MCK_nz2dd9.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453916/orglogos/mkesson_emqrke.png",
    "How to Contact (URL or email)": "https://mms.mckesson.com/catalog/category?node=1805540",
    "Linkedin": "https://www.linkedin.com/company/mckesson/",
    "Twitter": "https://twitter.com/McKesson",
    "Blog": "https://www.mckesson.com/About-McKesson/Impact/"
  },
  {
    "Offering name": "Enteral Feeding Pump Spike Set",
    "Company Name": "McKesson",
    "Description": "McKesson Medical-Surgical offers pump feeding supplies including enteral feeding pump bag sets, feeding pump spike sets, and feeding pumps.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Distributor",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.mckesson.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454735/orglogos/MCK_nz2dd9.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453916/orglogos/mkesson_emqrke.png",
    "How to Contact (URL or email)": "https://mms.mckesson.com/catalog?node=25970881",
    "Linkedin": "https://www.linkedin.com/company/mckesson/",
    "Twitter": "https://twitter.com/McKesson",
    "Blog": "https://www.mckesson.com/About-McKesson/Impact/"
  },
  {
    "Offering name": "Disposable Underpad McKesson",
    "Company Name": "McKesson",
    "Description": "Ultra absorbent polymer locks wetness away and reduces pH of urine for advanced odor control and skin wellness",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Distributor",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.mckesson.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454735/orglogos/MCK_nz2dd9.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453916/orglogos/mkesson_emqrke.png",
    "How to Contact (URL or email)": "https://mms.mckesson.com/catalog/category?node=7572",
    "Linkedin": "https://www.linkedin.com/company/mckesson/",
    "Twitter": "https://twitter.com/McKesson",
    "Blog": "https://www.mckesson.com/About-McKesson/Impact/"
  },
  {
    "Offering name": "Private Label/White Label Probiotics OEM/ODM",
    "Company Name": "TCI Bio",
    "Description": "TCI is a trusted private/white label manufacturer and is a global supplier of supplement and skin care products. TCI has a complete probiotic process, the best quality of probiotics, and can customize functional probiotics and patented prebiotics according to the customer’s needs.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Taiwan",
    "Country of Manufacture": "Made in Taiwan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tci-bio.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454933/orglogos/tci_jakajn.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685454878/orglogos/tci_jozbwe.png",
    "How to Contact (URL or email)": "https://www.tci-bio.com/technology/private-label-white-label-supplement-and-skin-care-product-oem-odm-2/",
    "Linkedin": "https://www.linkedin.com/company/tci-co-ltd/",
    "Twitter": "",
    "Blog": "https://www.tci-bio.com/esgs/"
  },
  {
    "Offering name": "Citrislim",
    "Company Name": "TCI Bio",
    "Description": "Ponkan (Citrus reticulata) is one of the most important commercial cultivars of mandarin that has been extensively planted in China, Japan and Taiwan since a very early date. It contains phenolics, flavonoids, terpenes, hesperidin and p-synephrine. The flavonoids extracts of C. reticulata show an inhibitory effect on fatty acid synthesis. Treatment with p-synephrine, which is the primary protoalkaloid in Citrus species, elevates glycogenolysis, glycolysis in vivo and metabolic rate in human, revealing the weight-loss effects of p-synephrine and citrus fruits.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Taiwan",
    "Country of Manufacture": "Made in Taiwan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tci-bio.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454933/orglogos/tci_jakajn.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685454878/orglogos/tci_jozbwe.png",
    "How to Contact (URL or email)": "https://www.tci-bio.com/technology/what-unique-raw-material-stories-does-tci-possess/",
    "Linkedin": "https://www.linkedin.com/company/tci-co-ltd/",
    "Twitter": "",
    "Blog": "https://www.tci-bio.com/esgs/"
  },
  {
    "Offering name": "TCI 711",
    "Company Name": "TCI Bio",
    "Description": "TCI711 is a probiotic that produces lactic acid. It is characterized by the ability to form endospores and stop growing to survive an unsuitable environment. After germination in the small intestine, the strain secretes not only lactic acid and short-chain fatty acids to modulate the gut flora, but also inhibit the growth of harmful bacteria. Studies have shown that Weizmannia coagulans(Bacillus coagulans) can help treat diarrhea caused by drug-resistant bacteria, and also help to improve the condition of inflammatory bowel disease.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Taiwan",
    "Country of Manufacture": "Made in Taiwan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tci-bio.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454933/orglogos/tci_jakajn.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685454878/orglogos/tci_jozbwe.png",
    "How to Contact (URL or email)": "https://www.tci-bio.com/technology/what-unique-raw-material-stories-does-tci-possess/",
    "Linkedin": "https://www.linkedin.com/company/tci-co-ltd/",
    "Twitter": "",
    "Blog": "https://www.tci-bio.com/esgs/"
  },
  {
    "Offering name": "Djulis",
    "Company Name": "TCI Bio",
    "Description": "Djulis (Chenopodium formosanum Koidz.) is a native cereal plant in Taiwan. It is traditionally called “ruby of cereals” because of bright red color of its grain. Besides the high content of dietary fiber and starch, djulis also possesses high protein levels and abundant essential amino acids, which make it a nutritious food.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Taiwan",
    "Country of Manufacture": "Made in Taiwan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.tci-bio.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454933/orglogos/tci_jakajn.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685454878/orglogos/tci_jozbwe.png",
    "How to Contact (URL or email)": "https://www.tci-bio.com/technology/what-unique-raw-material-stories-does-tci-possess/",
    "Linkedin": "https://www.linkedin.com/company/tci-co-ltd/",
    "Twitter": "",
    "Blog": "https://www.tci-bio.com/esgs/"
  },
  {
    "Offering name": "ISTODAX® (romidepsin)",
    "Company Name": "Bristol Myers Squibb",
    "Description": "ISTODAX is indicated for treatment of cutaneous T-cell lymphoma (CTCL) in patients who have received at least one prior systemic therapy.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bms.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "How to Contact (URL or email)": "https://packageinserts.bms.com/pi/pi_istodax.pdf",
    "Linkedin": "https://www.linkedin.com/company/bristol-myers-squibb",
    "Twitter": "https://twitter.com/bmsnews",
    "Blog": "https://www.bms.com/gb/about-us/responsibility.html"
  },
  {
    "Offering name": "POMALYST® (pomalidomide)",
    "Company Name": "Bristol Myers Squibb",
    "Description": "Pomalyst® (pomalidomide) is an oral capsule treatment for multiple myeloma.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bms.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "How to Contact (URL or email)": "https://www.pomalyst.com/",
    "Linkedin": "https://www.linkedin.com/company/bristol-myers-squibb",
    "Twitter": "https://twitter.com/bmsnews",
    "Blog": "https://www.bms.com/gb/about-us/responsibility.html"
  },
  {
    "Offering name": "DROXIA® (hydroxyurea)",
    "Company Name": "Bristol Myers Squibb",
    "Description": "Droxia (hydroxyurea) is an antimetabolite that affects certain cells in the body, such as cancer cells or sickled red blood cells.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bms.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "How to Contact (URL or email)": "https://packageinserts.bms.com/pi/pi_droxia.pdf",
    "Linkedin": "https://www.linkedin.com/company/bristol-myers-squibb",
    "Twitter": "https://twitter.com/bmsnews",
    "Blog": "https://www.bms.com/gb/about-us/responsibility.html"
  },
  {
    "Offering name": "IDHIFA®",
    "Company Name": "Bristol Myers Squibb",
    "Description": "IDHIFA® is the first and only treatment\noption for IDH2-mutated AML that has come back or not improved after previous treatment.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.bms.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "How to Contact (URL or email)": "https://www.idhifa.com/",
    "Linkedin": "https://www.linkedin.com/company/bristol-myers-squibb",
    "Twitter": "https://twitter.com/bmsnews",
    "Blog": "https://www.bms.com/gb/about-us/responsibility.html"
  },
  {
    "Offering name": "BNT111",
    "Company Name": "BioNTech",
    "Description": "Intravenously administered liposomal RNA",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biontech.com/int/en/home.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "How to Contact (URL or email)": "https://biontechse.gcs-web.com/static-files/b5a97ca9-efe5-485b-838c-940989ce8352",
    "Linkedin": "https://www.linkedin.com/company/biontech-se/",
    "Twitter": "https://twitter.com/BioNTech_Group",
    "Blog": ""
  },
  {
    "Offering name": "BNT113",
    "Company Name": "BioNTech",
    "Description": "IV administered liposomal ribonucleic acid",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biontech.com/int/en/home.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "How to Contact (URL or email)": "https://biontechse.gcs-web.com/static-files/b5a97ca9-efe5-485b-838c-940989ce8352",
    "Linkedin": "https://www.linkedin.com/company/biontech-se/",
    "Twitter": "https://twitter.com/BioNTech_Group",
    "Blog": ""
  },
  {
    "Offering name": "BNT311 (GEN 1046)",
    "Company Name": "BioNTech",
    "Description": "-",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biontech.com/int/en/home.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "How to Contact (URL or email)": "https://biontechse.gcs-web.com/static-files/b5a97ca9-efe5-485b-838c-940989ce8352",
    "Linkedin": "https://www.linkedin.com/company/biontech-se/",
    "Twitter": "https://twitter.com/BioNTech_Group",
    "Blog": ""
  },
  {
    "Offering name": "BNT331",
    "Company Name": "BioNTech",
    "Description": "Proprietary synthetic lysin candidate designed to degrade the peptidoglycan cell wall of Gardnerella bacteria",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biontech.com/int/en/home.html",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "How to Contact (URL or email)": "https://biontechse.gcs-web.com/static-files/b5a97ca9-efe5-485b-838c-940989ce8352",
    "Linkedin": "https://www.linkedin.com/company/biontech-se/",
    "Twitter": "https://twitter.com/BioNTech_Group",
    "Blog": ""
  },
  {
    "Offering name": "Evrenzo® (roxadustat)",
    "Company Name": "Astellas",
    "Description": "Evrenzo is indicated for treatment of adult patients with symptomatic anaemia associated with chronic kidney disease (CKD)",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.astellas.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "How to Contact (URL or email)": "https://www.evrenzo.co.uk/home-page",
    "Linkedin": "https://www.linkedin.com/company/astellaspharmainc/",
    "Twitter": "https://twitter.com/AstellasUS",
    "Blog": "https://www.astellas.com/en/sustainability"
  },
  {
    "Offering name": "XTANDI® (enzalutamide)",
    "Company Name": "Astellas",
    "Description": "XTANDI (enzalutamide) is a prescription medicine used to treat men with prostate cancer that:\nno longer responds to a hormone therapy or surgical treatment to lower testosterone OR\nhas spread to other parts of the body and responds to a hormone therapy or surgical treatment to lower testosterone.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.astellas.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "How to Contact (URL or email)": "https://www.xtandi.com/",
    "Linkedin": "https://www.linkedin.com/company/astellaspharmainc/",
    "Twitter": "https://twitter.com/AstellasUS",
    "Blog": "https://www.astellas.com/en/sustainability"
  },
  {
    "Offering name": "XOSPATA® (gilteritinib)",
    "Company Name": "Astellas",
    "Description": "XOSPATA was studied in people with FLT3m+ AML when the disease had come back or had not improved after previous treatment. The study compared overall survival in people who had taken XOSPATA vs those who received chemotherapy. In the study, the median overall survival for people who took XOSPATA was 9 months vs 6 months with chemotherapy.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.astellas.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "How to Contact (URL or email)": "https://xospata.com/",
    "Linkedin": "https://www.linkedin.com/company/astellaspharmainc/",
    "Twitter": "https://twitter.com/AstellasUS",
    "Blog": "https://www.astellas.com/en/sustainability"
  },
  {
    "Offering name": "PADCEV® (enfortumab vedotin)",
    "Company Name": "Astellas",
    "Description": "PADCEV can be used by itself or together with pembrolizumab (Keytruda®) to treat advanced* bladder cancer and cancers of the urinary tract (renal pelvis, ureter, or urethra).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.astellas.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "How to Contact (URL or email)": "https://www.padcev.com/",
    "Linkedin": "https://www.linkedin.com/company/astellaspharmainc/",
    "Twitter": "https://twitter.com/AstellasUS",
    "Blog": "https://www.astellas.com/en/sustainability"
  },
  {
    "Offering name": "Demser",
    "Company Name": "Ono Pharmaceutical",
    "Description": "This medication is used to treat high blood pressure in people with a certain adrenal gland tumor (pheochromocytoma).",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ono-pharma.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "How to Contact (URL or email)": "https://www.ono-pharma.com/sites/default/files/en/news/press/sm_cn190226_1.pdf",
    "Linkedin": "https://www.linkedin.com/company/ono-pharmaceutical-co-ltd/",
    "Twitter": "https://twitter.com/onopharmafound?lang=en",
    "Blog": "https://sustainability.ono-pharma.com/en"
  },
  {
    "Offering name": "Mektovi",
    "Company Name": "Ono Pharmaceutical",
    "Description": "BRAFTOVI + MEKTOVI is a combination therapy for adults with BRAF+ (V600E or V600K) melanoma that has spread (metastatic) or that cannot be removed by surgery. BRAFTOVI should not be used to treat people with wild-type BRAF melanoma.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ono-pharma.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "How to Contact (URL or email)": "https://www.braftovimektovi.com/",
    "Linkedin": "https://www.linkedin.com/company/ono-pharmaceutical-co-ltd/",
    "Twitter": "https://twitter.com/onopharmafound?lang=en",
    "Blog": "https://sustainability.ono-pharma.com/en"
  },
  {
    "Offering name": "Onon",
    "Company Name": "Ono Pharmaceutical",
    "Description": "Bronchial Asthma and Allergic Rhinitis",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ono-pharma.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "How to Contact (URL or email)": "https://www.ono-pharma.com/sites/default/files/en/news/press/sm_cn111222.pdf",
    "Linkedin": "https://www.linkedin.com/company/ono-pharmaceutical-co-ltd/",
    "Twitter": "https://twitter.com/onopharmafound?lang=en",
    "Blog": "https://sustainability.ono-pharma.com/en"
  },
  {
    "Offering name": "Adlumiz",
    "Company Name": "Ono Pharmaceutical",
    "Description": "Adlumiz is a medicine that contains the active substance anamorelin hydrochloride.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Japan",
    "Country of Manufacture": "Made in Japan",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.ono-pharma.com/en",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "How to Contact (URL or email)": "https://www.ono-pharma.com/en/news/20210421.html",
    "Linkedin": "https://www.linkedin.com/company/ono-pharmaceutical-co-ltd/",
    "Twitter": "https://twitter.com/onopharmafound?lang=en",
    "Blog": "https://sustainability.ono-pharma.com/en"
  },
  {
    "Offering name": "Argon",
    "Company Name": "BASF",
    "Description": "Argon is produced by BASF and is sold to commercial customers. The uses of Argon include: flushing gas for degassing of molten metal, as a protective gas for welding, casting, melting, forging and plating of metals.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.basf.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473206/orglogos/basf2_vyvvok.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473157/orglogos/basf_bqwkuv.png",
    "How to Contact (URL or email)": "https://products.basf.com/global/en/cp/argon.html",
    "Linkedin": "https://www.linkedin.com/company/basf/",
    "Twitter": "https://twitter.com/BASF",
    "Blog": "https://www.basf.com/global/en/who-we-are/sustainability.html"
  },
  {
    "Offering name": "Carbon Dioxide",
    "Company Name": "BASF",
    "Description": "Carbon dioxide is produced by BASF for the following uses: for the production of beverages, in comminuting, metering, dispensing and packaging of food, for extraction purposes, to isolate or remove flavourings from natural materials and for the production of dry-ice and pellets. Important characteristics of carbon dioxide: incombustible, colorless, odorless.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.basf.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473206/orglogos/basf2_vyvvok.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473157/orglogos/basf_bqwkuv.png",
    "How to Contact (URL or email)": "https://products.basf.com/global/en/cp/carbon-dioxide.html",
    "Linkedin": "https://www.linkedin.com/company/basf/",
    "Twitter": "https://twitter.com/BASF",
    "Blog": "https://www.basf.com/global/en/who-we-are/sustainability.html"
  },
  {
    "Offering name": "Ammonia Oxidation Catalyst (AMOX™)",
    "Company Name": "BASF",
    "Description": "BASF's Ammonia Oxidation catalyst (AMOX™) prevents ammonia from reaching the atmosphere.",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.basf.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473206/orglogos/basf2_vyvvok.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473157/orglogos/basf_bqwkuv.png",
    "How to Contact (URL or email)": "https://catalysts.basf.com/products/amox-ammonia-oxidation-catalyst",
    "Linkedin": "https://www.linkedin.com/company/basf/",
    "Twitter": "https://twitter.com/BASF",
    "Blog": "https://www.basf.com/global/en/who-we-are/sustainability.html"
  },
  {
    "Offering name": "Boroflex®",
    "Company Name": "BASF",
    "Description": "Fluid Catalytic Cracking (FCC) Catalyst for upgrading highly contaminated residue feeds",
    "Category": "Chemicals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.basf.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473206/orglogos/basf2_vyvvok.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473157/orglogos/basf_bqwkuv.png",
    "How to Contact (URL or email)": "https://catalysts.basf.com/products/boroflex",
    "Linkedin": "https://www.linkedin.com/company/basf/",
    "Twitter": "https://twitter.com/BASF",
    "Blog": "https://www.basf.com/global/en/who-we-are/sustainability.html"
  },
  {
    "Offering name": "De-icing",
    "Company Name": "Menzies Aviation",
    "Description": "We are the de-icing specialists, and always ensure our people and equipment are in the right place at the right time, regardless of how quickly the weather deteriorates. We spray more than 41,000 aircraft with 15 million litres of de-icing fluid annually so our airline customers know they can stick to their schedule however unpredictable the weather.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://menziesaviation.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "How to Contact (URL or email)": "https://menziesaviation.com/services/ground-handling/",
    "Linkedin": "https://www.linkedin.com/company/menzies-aviation/",
    "Twitter": "",
    "Blog": "https://menziesaviation.com/about-overview/corporate-responsibility-and-sustainability/"
  },
  {
    "Offering name": "Passenger services",
    "Company Name": "Menzies Aviation",
    "Description": "Welcoming passengers and providing first-rate customer services during their journey are responsibilities of the utmost importance, particularly as our agents are often the passenger’s first connection with the customer airline.  Our team provides friendly, efficient and tailored passenger services from check-in to boarding and back again at more than 120 locations.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://menziesaviation.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "How to Contact (URL or email)": "https://menziesaviation.com/services/ground-handling/",
    "Linkedin": "https://www.linkedin.com/company/menzies-aviation/",
    "Twitter": "",
    "Blog": "https://menziesaviation.com/about-overview/corporate-responsibility-and-sustainability/"
  },
  {
    "Offering name": "Fuel services",
    "Company Name": "Menzies Aviation",
    "Description": "We are the world’s largest independent aviation fuelling services provider with over 3,000 people working for us globally. Our fuels business provides fuel storage management and into-plane fueling services for oil companies, airports, and  airlines at many of the world’s largest airports.  Innovative approaches to aviation fuels have a vital role to play in tackling climate change and we are proud to be working closely with our partners as we move forward to decarbonise operations.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://menziesaviation.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "How to Contact (URL or email)": "https://menziesaviation.com/services/fuelling/#:~:text=We%20are%20the%20world's%20largest,of%20the%20world's%20largest%20airports.",
    "Linkedin": "https://www.linkedin.com/company/menzies-aviation/",
    "Twitter": "",
    "Blog": "https://menziesaviation.com/about-overview/corporate-responsibility-and-sustainability/"
  },
  {
    "Offering name": "Air cargo services",
    "Company Name": "Menzies Aviation",
    "Description": "We manage the global transportation of high value and time critical cargo. Decades of experience, supported by cost-effective and smart logistics solutions, means we are trusted to provide a reliable and secure service. Our cargo network now covers 70 locations across the globe and continues to grow.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://menziesaviation.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "How to Contact (URL or email)": "https://menziesaviation.com/services/cargo/",
    "Linkedin": "https://www.linkedin.com/company/menzies-aviation/",
    "Twitter": "",
    "Blog": "https://menziesaviation.com/about-overview/corporate-responsibility-and-sustainability/"
  },
  {
    "Offering name": "Drop and Ship",
    "Company Name": "Aramex",
    "Description": "Through Drop and Ship, Aramex provides you with a dedicated forwarding import box. Simply ask your suppliers to ship domestically to your Drop and Ship box and Aramex will forward your packages to your doorstep.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.aramex.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "How to Contact (URL or email)": "https://www.aramex.com/za/en/solutions-services/drop-ship",
    "Linkedin": "https://www.linkedin.com/company/aramex/",
    "Twitter": "https://twitter.com/aramex",
    "Blog": "https://www.aramex.com/us/en/aramex-2022-annual-report/sustainability"
  },
  {
    "Offering name": "aramexgo",
    "Company Name": "Aramex",
    "Description": "Shipping just got easier with our solutions for entrepreneurs that are starting up, social sellers and everyone else.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.aramex.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "How to Contact (URL or email)": "https://www.aramexgo.com/en",
    "Linkedin": "https://www.linkedin.com/company/aramex/",
    "Twitter": "https://twitter.com/aramex",
    "Blog": "https://www.aramex.com/us/en/aramex-2022-annual-report/sustainability"
  },
  {
    "Offering name": "Sea freight",
    "Company Name": "Aramex",
    "Description": "Ship bulk with Full Container Load (FCL), project cargo, OOG cargo (Out of Gauge Cargo), chartering cargo ships, hazardous cargo for dangerous goods and refer cargo.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.aramex.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "How to Contact (URL or email)": "https://www.aramex.com/za/en/services-solutions/freight-logistics",
    "Linkedin": "https://www.linkedin.com/company/aramex/",
    "Twitter": "https://twitter.com/aramex",
    "Blog": "https://www.aramex.com/us/en/aramex-2022-annual-report/sustainability"
  },
  {
    "Offering name": "ClickToShip",
    "Company Name": "Aramex",
    "Description": "ClickToShip is a Smart Shipping Software that automates shipment preparations and pick‐up requests, calculates shipping rates, tracks shipments and manages your shipping addresses.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.aramex.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "How to Contact (URL or email)": "https://www.aramex.com/us/en/services-solutions/clicktoship",
    "Linkedin": "https://www.linkedin.com/company/aramex/",
    "Twitter": "https://twitter.com/aramex",
    "Blog": "https://www.aramex.com/us/en/aramex-2022-annual-report/sustainability"
  },
  {
    "Offering name": "Camps, Catering & Construction",
    "Company Name": "Agility Logistics",
    "Description": "GCC Services is a highly respected and renowned integrated remote services company. Our award-winning services include camp catering, integrated facilities management and camp construction.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.agility.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "How to Contact (URL or email)": "https://www.agility.com/en/infrastructure-companies/subsidiaries-gcc-services/",
    "Linkedin": "https://www.linkedin.com/company/agility/",
    "Twitter": "https://twitter.com/agility",
    "Blog": "https://sustainability.agility.com/"
  },
  {
    "Offering name": "Customs Digitization",
    "Company Name": "Agility Logistics",
    "Description": "GCS’s comprehensive modernization and trade facilitation solutions help countries deploy the systems and technology they need to improve management control, increase revenues, and comply with their obligations under multinational (WTO, WCO, and GATT) and bilateral trade agreements.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.agility.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "How to Contact (URL or email)": "https://www.agility.com/en/infrastructure-companies/subsidiaries-gcs/",
    "Linkedin": "https://www.linkedin.com/company/agility/",
    "Twitter": "https://twitter.com/agility",
    "Blog": "https://sustainability.agility.com/"
  },
  {
    "Offering name": "Laboratory Services",
    "Company Name": "Agility Logistics",
    "Description": "LABCO provides third party quality control and assurance services, using scientific analytical data for microbiological and chemical testing, along with total quality management services.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.agility.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "How to Contact (URL or email)": "https://www.agility.com/en/infrastructure-companies/subsidiaries-labco/",
    "Linkedin": "https://www.linkedin.com/company/agility/",
    "Twitter": "https://twitter.com/agility",
    "Blog": "https://sustainability.agility.com/"
  },
  {
    "Offering name": "Aviation Services",
    "Company Name": "Agility Logistics",
    "Description": "Menzies Aviation is the world’s largest aviation services company. It provides ground, fuel and air cargo services to 500+ partners and customers at more than 250 airports in 50+ countries worldwide. The 35,000-strong global Menzies team sets the industry standard for safety, security and quality in aviation services.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "Service Provider",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.agility.com/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "How to Contact (URL or email)": "https://www.agility.com/en/infrastructure-companies/menzies-aviation/",
    "Linkedin": "https://www.linkedin.com/company/agility/",
    "Twitter": "https://twitter.com/agility",
    "Blog": "https://sustainability.agility.com/"
  },
  {
    "Offering name": "Probiotics",
    "Company Name": "Farmaceutici Procemsa",
    "Description": "Probiotic-based food supplements are developed in a specially equipped area of the Research and Development laboratory and entirely produced in a plant that is completely independent from all other food products to ensure the highest standards of safety and efficacy.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Italy",
    "Country of Manufacture": "Made in Italy",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.procemsa.it/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685475408/orglogos/procemsa_zb3d7t.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475126/orglogos/procemsa_nhwtik.png",
    "How to Contact (URL or email)": "https://www.procemsa.it/en/services/probiotics/",
    "Linkedin": "https://www.linkedin.com/company/farmaceutici-procemsa-s.p.a./?originalSubdomain=it",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Cosmetics",
    "Company Name": "Farmaceutici Procemsa",
    "Description": "We develop and produce different types of cosmetics in a dedicated area of the plant:",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Italy",
    "Country of Manufacture": "Made in Italy",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.procemsa.it/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685475408/orglogos/procemsa_zb3d7t.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475126/orglogos/procemsa_nhwtik.png",
    "How to Contact (URL or email)": "https://www.procemsa.it/en/services/cosmetics/",
    "Linkedin": "https://www.linkedin.com/company/farmaceutici-procemsa-s.p.a./?originalSubdomain=it",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Food supplements",
    "Company Name": "Farmaceutici Procemsa",
    "Description": "Food supplements are completely created within the Research & Development department, where the best performing formulas for the best possible product are designed daily to ensure efficacy, safety and pleasantness.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Italy",
    "Country of Manufacture": "Made in Italy",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.procemsa.it/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685475408/orglogos/procemsa_zb3d7t.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475126/orglogos/procemsa_nhwtik.png",
    "How to Contact (URL or email)": "https://www.procemsa.it/en/services/food-supplements/",
    "Linkedin": "https://www.linkedin.com/company/farmaceutici-procemsa-s.p.a./?originalSubdomain=it",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Medical devices",
    "Company Name": "Farmaceutici Procemsa",
    "Description": "We develop and produce as medical device manufacturers in all classes, in all plants. We specialize in the treatment of oral mucous membranes, having 6 products in class IIa; in the gastro and nasal area.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Italy",
    "Country of Manufacture": "Made in Italy",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.procemsa.it/en/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685475408/orglogos/procemsa_zb3d7t.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475126/orglogos/procemsa_nhwtik.png",
    "How to Contact (URL or email)": "https://www.procemsa.it/en/services/medical-devices/",
    "Linkedin": "https://www.linkedin.com/company/farmaceutici-procemsa-s.p.a./?originalSubdomain=it",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Offering name": "Dotarem",
    "Company Name": "Guerbet",
    "Description": "Dotarem® is licensed for patients of ALL ages for enhancement of contrast for Encephalic and spinal MRI, whole body MRI (including cardiac, breast, uterine, ovarian, abdominal, renal, and osteo-articular pathology) and for adults (18 year and above) in angiography16.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.guerbet.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476020/orglogos/nice_otsoi1.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475560/orglogos/guerbet_ytsk7j.png",
    "How to Contact (URL or email)": "https://www.guerbet.com/en-gb/healthcare-professionals/products-solutions/contrast-agents/dotarem",
    "Linkedin": "https://www.linkedin.com/company/guerbet/",
    "Twitter": "https://twitter.com/GuerbetGroup/",
    "Blog": "https://www.guerbet.com/our-group/our-responsibility"
  },
  {
    "Offering name": "Optiray®",
    "Company Name": "Guerbet",
    "Description": "Optiray® was first available in 1989 and also became available in the pre-filled syringe form for manual or automatic injection in 1996 (Optiject®) in France, Belgium, Luxembourg, and Switzerland. It is known as Ultraject® in the rest of the world.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.guerbet.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476020/orglogos/nice_otsoi1.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475560/orglogos/guerbet_ytsk7j.png",
    "How to Contact (URL or email)": "https://www.guerbet.com/products-solutions/contrast-agents/optiray",
    "Linkedin": "https://www.linkedin.com/company/guerbet/",
    "Twitter": "https://twitter.com/GuerbetGroup/",
    "Blog": "https://www.guerbet.com/our-group/our-responsibility"
  },
  {
    "Offering name": "Conray® / Cysto-Conray®",
    "Company Name": "Guerbet",
    "Description": "Conray® was launched in 1962. Available in Canada, Mexico and the USA only.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.guerbet.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476020/orglogos/nice_otsoi1.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475560/orglogos/guerbet_ytsk7j.png",
    "How to Contact (URL or email)": "https://www.guerbet.com/products-solutions/contrast-agents/conray-cysto-conray",
    "Linkedin": "https://www.linkedin.com/company/guerbet/",
    "Twitter": "https://twitter.com/GuerbetGroup/",
    "Blog": "https://www.guerbet.com/our-group/our-responsibility"
  },
  {
    "Offering name": "Micropaque® / Microtrast®",
    "Company Name": "Guerbet",
    "Description": "The Micropaque® range was first launched in Belgium in 1961 and is now available in 19 countries.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.guerbet.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476020/orglogos/nice_otsoi1.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475560/orglogos/guerbet_ytsk7j.png",
    "How to Contact (URL or email)": "https://www.guerbet.com/products-solutions/contrast-agents/micropaque-microtrast",
    "Linkedin": "https://www.linkedin.com/company/guerbet/",
    "Twitter": "https://twitter.com/GuerbetGroup/",
    "Blog": "https://www.guerbet.com/our-group/our-responsibility"
  },
  {
    "Offering name": "Operetta CLS High Content Analysis System",
    "Company Name": "PerkinElmer",
    "Description": "Operetta® CLS™ is a high throughput microplate imager for high-content analysis (HCA). It can acquire, analyze and manage fluorescence, brightfield and digital phase contrast images. The combination of high power LED excitation with fast, precise mechanics and one large format sCMOS camera enables fast imaging. Controlled by Harmony™ software it offers a seamless workflow, for reliable discrimination of phenotypes even in complex cellular models.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.perkinelmer.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476306/orglogos/pel_dmpnkw.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476317/orglogos/pe_bqhxt1.png",
    "How to Contact (URL or email)": "https://www.perkinelmer.com/uk/category/operetta-cls-high-content-analysis-system",
    "Linkedin": "https://www.linkedin.com/company/perkinelmer/",
    "Twitter": "https://twitter.com/PerkinElmer",
    "Blog": ""
  },
  {
    "Offering name": "PhenoVue Live/Dead Cell Viability Assay Kit",
    "Company Name": "PerkinElmer",
    "Description": "PhenoVue™ Live/Dead Cell Viability Assay Kit enables the simultaneous determination of live and dead cells and is part of Revvity’s portfolio of cellular imaging reagents. Calcein AM dye is a cell permeable fluorescent dye which generates a bright green, fluorescent signal, whereas Propidium Iodide dye enters damaged cell membranes of dead cells and exhibits a bright red fluorescence. This assay kit provides an easy-to-use method for measuring cell proliferation, cell viability, cytotoxicity and apoptosis.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.perkinelmer.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476306/orglogos/pel_dmpnkw.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476317/orglogos/pe_bqhxt1.png",
    "How to Contact (URL or email)": "https://www.perkinelmer.com/uk/product/phenovue-live-dead-cell-viability-kit-pcva11",
    "Linkedin": "https://www.linkedin.com/company/perkinelmer/",
    "Twitter": "https://twitter.com/PerkinElmer",
    "Blog": ""
  },
  {
    "Offering name": "PhenoVue Fura-2 AM, Calcium Indicator",
    "Company Name": "PerkinElmer",
    "Description": "PhenoVue™ Fura-2 AM, is a high affinity and permeable fluorescent ratiometric Ca2+ indicator and is part of Revvity’s portfolio of cellular imaging reagents. PhenoVue Fura-2 AM Ca2+ indicator exhibits bright green fluorescence and is validated for use in imaging microscopy and high-content screening applications. It exhibits a maximum excitation wavelength shift from 336 to 365 nm upon binding to free intracellular Ca2+, and a maximum emission wavelength of 505 nm.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.perkinelmer.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476306/orglogos/pel_dmpnkw.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476317/orglogos/pe_bqhxt1.png",
    "How to Contact (URL or email)": "https://www.perkinelmer.com/uk/product/phenovue-fura-2-am-cp141",
    "Linkedin": "https://www.linkedin.com/company/perkinelmer/",
    "Twitter": "https://twitter.com/PerkinElmer",
    "Blog": ""
  },
  {
    "Offering name": "PhenoVue Cal-520 AM Bright, Calcium Indicator",
    "Company Name": "PerkinElmer",
    "Description": "PhenoVue™ Cal-520 AM is a cell permeable fluorescent Ca2+ indicator and is part of Revvity’s portfolio of cellular imaging reagents. PhenoVue Cal-520 AM Ca2+ indicator exhibits green fluorescence and is validated for use in imaging microscopy and high-content screening applications. It exhibits a maximum excitation wavelength at 493 nm and a maximum emission wavelength of 515 nm.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.perkinelmer.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476306/orglogos/pel_dmpnkw.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476317/orglogos/pe_bqhxt1.png",
    "How to Contact (URL or email)": "https://www.perkinelmer.com/uk/product/phenovue-cal-520-am-cp125201",
    "Linkedin": "https://www.linkedin.com/company/perkinelmer/",
    "Twitter": "https://twitter.com/PerkinElmer",
    "Blog": ""
  },
  {
    "Offering name": "QuantiFERON-TB Gold Plus (QFT-Plus)",
    "Company Name": "QIAGEN",
    "Description": "We understand the importance of early detection and treatment of TB. That is why we are committed to providing a reliable, accurate and convenient test for patients and doctors alike. With QFT-Plus you can perform your TB testing with just one visit, one tube and one objective result. See how this #1 TB blood test can help you make informed decisions about patient care faster",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.qiagen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476940/orglogos/qiqi_ehvw12.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476439/orglogos/qiagen_knp3qa.png",
    "How to Contact (URL or email)": "https://www.qiagen.com/us/applications/tb-management/tb-testing/qft-plus",
    "Linkedin": "https://www.linkedin.com/company/qiagen/",
    "Twitter": "https://twitter.com/QIAGEN",
    "Blog": "https://www.qiagen.com/us/sustainability"
  },
  {
    "Offering name": "RNase-Free DNase Set (50)",
    "Company Name": "QIAGEN",
    "Description": "1500 Kunitz units RNase-free DNase I, RNase-free Buffer RDD, and RNase-free water for 50 RNA minipreps",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.qiagen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476940/orglogos/qiqi_ehvw12.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476439/orglogos/qiagen_knp3qa.png",
    "How to Contact (URL or email)": "https://www.qiagen.com/us/products/discovery-and-translational-research/lab-essentials/enzymes/rnase-free-dnase-set",
    "Linkedin": "https://www.linkedin.com/company/qiagen/",
    "Twitter": "https://twitter.com/QIAGEN",
    "Blog": "https://www.qiagen.com/us/sustainability"
  },
  {
    "Offering name": "RNeasy Micro Kit (50)",
    "Company Name": "QIAGEN",
    "Description": "50 RNeasy MinElute Spin Columns, Collection Tubes (1.5 ml and 2 ml), RNase-free DNase I, Carrier RNA, RNase-free Reagents and Buffers",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.qiagen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476940/orglogos/qiqi_ehvw12.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476439/orglogos/qiagen_knp3qa.png",
    "How to Contact (URL or email)": "https://www.qiagen.com/us/products/discovery-and-translational-research/dna-rna-purification/rna-purification/total-rna/rneasy-kits",
    "Linkedin": "https://www.linkedin.com/company/qiagen/",
    "Twitter": "https://twitter.com/QIAGEN",
    "Blog": "https://www.qiagen.com/us/sustainability"
  },
  {
    "Offering name": "QIAquick PCR Purification Kit (50)",
    "Company Name": "QIAGEN",
    "Description": "For purification of 50 PCR reactions: 50 QIAquick Spin Columns, Buffers, Collection Tubes (2 ml)",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Germany",
    "Country of Manufacture": "Made in Germany",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.qiagen.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476940/orglogos/qiqi_ehvw12.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476439/orglogos/qiagen_knp3qa.png",
    "How to Contact (URL or email)": "https://www.qiagen.com/us/products/discovery-and-translational-research/dna-rna-purification/dna-purification/dna-clean-up/qiaquick-pcr-purification-kit",
    "Linkedin": "https://www.linkedin.com/company/qiagen/",
    "Twitter": "https://twitter.com/QIAGEN",
    "Blog": "https://www.qiagen.com/us/sustainability"
  },
  {
    "Offering name": "SARS-COV-2 RESPI R-GENE®",
    "Company Name": "bioMérieux",
    "Description": "Real-time PCR kit for the detection of SARS-CoV-2, Influenza A, Influenza B, RSV A&B and hMPV A&B",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biomerieux.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685477005/orglogos/bio_mab0d3.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476522/orglogos/bio_dvgbbe.png",
    "How to Contact (URL or email)": "https://www.biomerieux-diagnostics.com/sars-cov-2-respi-r-gener",
    "Linkedin": "https://www.linkedin.com/company/biomerieux/?originalSubdomain=uk",
    "Twitter": "https://twitter.com/biomerieux",
    "Blog": "https://www.biomerieux.com/corp/en/our-responsibility.html"
  },
  {
    "Offering name": "ADENOVIRUS R-GENE®",
    "Company Name": "bioMérieux",
    "Description": "Real-time PCR kit for the detection and quantification of Adenovirus (ADV) DNA",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biomerieux.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685477005/orglogos/bio_mab0d3.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476522/orglogos/bio_dvgbbe.png",
    "How to Contact (URL or email)": "https://www.biomerieux-diagnostics.com/adenovirus-r-gener",
    "Linkedin": "https://www.linkedin.com/company/biomerieux/?originalSubdomain=uk",
    "Twitter": "https://twitter.com/biomerieux",
    "Blog": "https://www.biomerieux.com/corp/en/our-responsibility.html"
  },
  {
    "Offering name": "VIDAS® GDH",
    "Company Name": "bioMérieux",
    "Description": "The VIDAS® C. difficile GDH and TOXIN A & B (CDAB) are two complementary tests used as an aid in the diagnosis of C. difficile associated disease.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biomerieux.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685477005/orglogos/bio_mab0d3.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476522/orglogos/bio_dvgbbe.png",
    "How to Contact (URL or email)": "https://www.biomerieux-usa.com/vidas-cdifficile",
    "Linkedin": "https://www.linkedin.com/company/biomerieux/?originalSubdomain=uk",
    "Twitter": "https://twitter.com/biomerieux",
    "Blog": "https://www.biomerieux.com/corp/en/our-responsibility.html"
  },
  {
    "Offering name": "apiweb™",
    "Company Name": "bioMérieux",
    "Description": "On-line bacteria and yeast database accessible worldwide anytime, anywhere, for everyone.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in France",
    "Country of Manufacture": "Made in France",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.biomerieux.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685477005/orglogos/bio_mab0d3.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476522/orglogos/bio_dvgbbe.png",
    "How to Contact (URL or email)": "https://www.biomerieux.co.uk/product/apiwebtm",
    "Linkedin": "https://www.linkedin.com/company/biomerieux/?originalSubdomain=uk",
    "Twitter": "https://twitter.com/biomerieux",
    "Blog": "https://www.biomerieux.com/corp/en/our-responsibility.html"
  },
  {
    "Offering name": "AmpliSeq for Illumina BRCA Panel",
    "Company Name": "Illumina",
    "Description": "Targeted research panel investigating somatic and germline variants in BRCA1 and BRCA2.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.illumina.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu2_pisstl.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu_a3yski.png",
    "How to Contact (URL or email)": "https://emea.illumina.com/products/by-type/sequencing-kits/library-prep-kits/ampliseq-brca-panel.html",
    "Linkedin": "https://www.linkedin.com/company/illumina/",
    "Twitter": "https://twitter.com/illumina",
    "Blog": "https://www.illumina.com/company/about-us/corporate-social-responsibility/environmental-social-governance.html"
  },
  {
    "Offering name": "Illumina RNA Prep with Enrichment",
    "Company Name": "Illumina",
    "Description": "Achieve rapid, targeted interrogation of an expansive number of target genes with exceptional capture efficiency and coverage uniformity.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.illumina.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu2_pisstl.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu_a3yski.png",
    "How to Contact (URL or email)": "https://emea.illumina.com/products/by-type/sequencing-kits/library-prep-kits/rna-prep-enrichment.html",
    "Linkedin": "https://www.linkedin.com/company/illumina/",
    "Twitter": "https://twitter.com/illumina",
    "Blog": "https://www.illumina.com/company/about-us/corporate-social-responsibility/environmental-social-governance.html"
  },
  {
    "Offering name": "Urinary Pathogen ID/AMR Enrichment Kit",
    "Company Name": "Illumina",
    "Description": "The Urinary Pathogen ID/AMR Panel provides highly sensitive, culture-free identification and quantification of common and underrecognized uropathogens.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.illumina.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu2_pisstl.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu_a3yski.png",
    "How to Contact (URL or email)": "https://emea.illumina.com/products/by-type/sequencing-kits/library-prep-kits/urinary-pathogen-id-amr-enrichment-kit.html",
    "Linkedin": "https://www.linkedin.com/company/illumina/",
    "Twitter": "https://twitter.com/illumina",
    "Blog": "https://www.illumina.com/company/about-us/corporate-social-responsibility/environmental-social-governance.html"
  },
  {
    "Offering name": "HiSeq SBS Kit v4",
    "Company Name": "Illumina",
    "Description": "Includes sequencing reagents specifically designed for the HiSeq 3000/HiSeq 4000 Systems. Enables production-scale sequencing for diverse applications.",
    "Category": "Transport / Logistics",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in United States",
    "Country of Manufacture": "Made in United States",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.illumina.com/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu2_pisstl.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu_a3yski.png",
    "How to Contact (URL or email)": "https://emea.illumina.com/products/by-type/sequencing-kits/cluster-gen-sequencing-reagents/hiseq-3000-4000-sbs-kit.html",
    "Linkedin": "https://www.linkedin.com/company/illumina/",
    "Twitter": "https://twitter.com/illumina",
    "Blog": "https://www.illumina.com/company/about-us/corporate-social-responsibility/environmental-social-governance.html"
  },
  {
    "Offering name": "Adtralza®▼",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://uk.dermaworld.eu/treatments/adtralza/home",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "Finacea® gel",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://www.leo-pharma.co.uk/our-focus/our-products",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "Dovobet® ointment",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://www.leo-pharma.co.uk/our-focus/our-products",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "Fucibet® cream",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add products with their credentials to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Selling",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://www.leo-pharma.co.uk/our-focus/our-products",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "Adtralza®▼",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://uk.dermaworld.eu/treatments/adtralza/home",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "Finacea® gel",
    "Company Name": "Leo Pharma",
    "Description": "Speak with our team to claim Leo Pharma's profile and add any product donation programmes to this storefront.",
    "Category": "Pharmaceuticals",
    "Selling or Donating": "Donating",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "Supplies to Every Continent",
    "Country of Design": "Designed in Denmark",
    "Country of Manufacture": "Made in Denmark",
    "Type of Business": "Manufacturer",
    "Certification 1": "FDA CCP",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "Yes",
    "URL": "https://www.leo-pharma.co.uk/",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to Contact (URL or email)": "https://www.leo-pharma.co.uk/our-focus/our-products",
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  },
  {
    "Offering name": "",
    "Company Name": "",
    "Description": "",
    "Category": "",
    "Selling or Donating": "",
    "SKU": "",
    "Manufacturer ID": "",
    "UPC": "",
    "Number of items per sales unit": "",
    "Sales unit sold in multiples of": "",
    "MoQ": "",
    "Days to ship": "",
    "Supplies to": "",
    "Country of Design": "",
    "Country of Manufacture": "",
    "Type of Business": "",
    "Certification 1": "",
    "Certification 2": "",
    "Certification 3": "",
    "Certification 4": "",
    "Certification 5": "",
    "Certification 6": "",
    "Certification 7": "",
    "Certification 8": "",
    "Plastic in item or packaging": "",
    "URL": "#N/A",
    "Logo URL": "#N/A",
    "Favicon": "#N/A",
    "How to Contact (URL or email)": "#N/A",
    "Linkedin": "#N/A",
    "Twitter": "#N/A",
    "Blog": "#N/A"
  }
]