export const services = [
  {
    "Name": "Janssen",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.janssen.com/",
    "Description": "We never stop working to create a future where disease is a thing of the past.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/janssen",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively practices inclusion",
    "Value 4": "Engages with community",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "Designed in Europe",
    "Location 3": "Made / Provided in Europe",
    "Location 4": "Supplies to Every Continent",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1657022931/orglogos/Janssen-logo-880x660-e1572451493693_k8cqiv.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/janssen-pharmaceutical-companies-of-johnson-and-johnson",
    "Twitter": "https://twitter.com/JanssenEMEA",
    "Blog": "https://www.janssen.com/blogs"
  },
  {
    "Name": "Salvat",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://svt.com/en/",
    "Description": "We share a deep commitment to innovation and employment generation. By always putting patients first, we can achieve our primary objective: contribution to a healthier and happier life.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/salvat",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Science-driven",
    "Value 2": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 10+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "Supplies to Every Continent",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1684407945/orglogos/salavat_rvm01y.jpg",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/salvat/",
    "Twitter": "https://twitter.com/salvatpharma?lang=en",
    "Blog": "https://svt.com/en/commitment/"
  },
  {
    "Name": "Ineos",
    "Approved": "Yes",
    "Category": "Chemicals",
    "URL": "https://www.ineos.com/",
    "Description": "We make the raw materials and energy used in everyday life.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/ineos",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "Actively practices inclusion",
    "Value 5": "Engages with community",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "Designed in Europe",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231179/orglogos/1645611703705-removebg-preview_fdiudv.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/airtable/",
    "Twitter": "https://twitter.com/INEOS",
    "Blog": "https://www.ineos.com/sustainability/"
  },
  {
    "Name": "GSK",
    "Approved": "Yes",
    "Category": "Pharmaceuticals",
    "URL": "https://www.gsk.com/",
    "Description": "Our purpose is to unite science, technology and talent to get ahead of disease together.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/gsk",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 2": "Set Net-Zero Waste Target",
    "Value 3": "Set Net-Zero Freshwater Systems Degradation Target",
    "Value 4": "Set Net-Zero Biodiversity Loss Target",
    "Value 5": "Published modern slavery statement",
    "Value 6": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 7": "Actively practices inclusion",
    "Value 8": "Engages with community",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "Designed in Europe",
    "Location 3": "Supplies to Every Continent",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231228/orglogos/1656408405645-removebg-preview_pufftc.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/glaxosmithkline/",
    "Twitter": "https://twitter.com/GSK",
    "Blog": "https://www.gsk.com/en-gb/responsibility/"
  },
  {
    "Name": "Stryker",
    "Approved": "Yes",
    "Category": "Medical Devices",
    "URL": "https://www.stryker.com/",
    "Description": "Together with our customers, we are driven to make healthcare better.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/stryker",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "Actively practices inclusion",
    "Value 5": "Engages with community",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Designed in North America",
    "Location 2": "Made / Provided in North America",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660231046/orglogos/1519856221451-removebg-preview_md0nry.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/stryker/",
    "Twitter": "https://twitter.com/StrykerComm",
    "Blog": "https://www.stryker.com/us/en/about/corporate-responsibility.html"
  },
  {
    "Name": "BorsodChem",
    "Approved": "Yes",
    "Category": "Chemicals",
    "URL": "https://borsodchem.com/",
    "Description": "With the continuous development of chemistry, we seek to improve well-being of the society.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling and Donating",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/borsodchem",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Carbon Emissions Target",
    "Value 2": "Set Net-Zero Waste Target",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "All members of key board committees are independent",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "Made / Provided in Europe",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1660230935/orglogos/borsodchem-squarelogo-1646735956043-removebg-preview_zpowsx.png",
    "Number of offerings": 6,
    "Linkedin": "https://www.linkedin.com/company/borsodchem/",
    "Twitter": "",
    "Blog": "https://borsodchem.com/en/sustainability"
  },
  {
    "Name": "Bayer",
    "Approved": "Yes",
    "Category": "Pharmaceuticals",
    "URL": "https://www.bayer.com/en/",
    "Description": "We address some of the world's most pressing global challenges and continue to develop new solutions. The population is constantly growing and its age is increasing. That is why it needs better medicines and high-quality food in sufficient quantities.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling and Donating",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/bayer",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 2": "Invests via its Venture Impact Fund",
    "Value 3": "Published modern slavery statement",
    "Value 4": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 5": "Actively practices inclusion",
    "Value 6": "Engages with community",
    "Value 7": "Has Corporate Responsibility / Sustainability Board Committee",
    "Value 8": "Ensures pay equity",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Supplies to Every Continent",
    "Location 2": "Europe HQ",
    "Location 3": "Designed in Europe",
    "Location 4": "Local Office in Every Continent",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1662579461/orglogos/Logo_Bayer.svg_jkhggf.png",
    "Number of offerings": 6,
    "Linkedin": "https://www.linkedin.com/company/bayer/",
    "Twitter": "https://twitter.com/bayer",
    "Blog": "https://www.bayer.com/en/news-stories"
  },
  {
    "Name": "Teva",
    "Approved": "Yes",
    "Category": "Pharmaceuticals",
    "URL": "https://www.tevapharm.com/",
    "Description": "We believe that everyone should have access to quality medicine that helps manage disease, fights infection, or simply improves overall health.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling and Donating",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1664394749/orglogos/1600px-Teva_Pharmaceuticals_logo_znl0lw.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/teva",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Issued Sustainability-Linked Bond",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "Actively practices inclusion",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Supplies to Every Continent",
    "Location 2": "Asia HQ",
    "Location 3": "Designed in Asia",
    "Location 4": "Local Office in Every Continent",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1664396916/orglogos/teva_ef2ubd.png",
    "Number of offerings": 6,
    "Linkedin": "https://www.linkedin.com/company/teva-pharmaceuticals/",
    "Twitter": "https://twitter.com/tevausa",
    "Blog": "https://www.tevapharm.com/our-impact/"
  },
  {
    "Name": "Dr. Reddy's",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.drreddys.com/",
    "Description": "We accelerate access to affordable and innovative medicines because Good Health Can’t Wait.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/drreddys",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Waste Target",
    "Value 2": "Set Net-Zero Freshwater Systems Degradation Target",
    "Value 3": "Published modern slavery statement",
    "Value 4": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 5": "Engages with community",
    "Value 6": "All members of key board committees are independent",
    "Value 7": "Has Corporate Responsibility / Sustainability Board Committee",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "Made / Provided in Asia",
    "Location 3": "Supplies to Every Continent",
    "Location 4": "Europe Local Office",
    "Location 5": "North America Local Office",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1681320515/orglogos/dr-reddys-laboratories-vector-logo-small_ud252j.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/dr--reddys-laboratories/?trk=nav_account_sub_nav_company_admin",
    "Twitter": "https://twitter.com/drreddys",
    "Blog": "https://www.drreddys.com/#sustainability"
  },
  {
    "Name": "BioGaia",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.biogaia.com/",
    "Description": "Health and science are at the heart of everything we do.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685115334/orglogos/biogaia_bjfako.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/biogaia",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 2": "Actively practices inclusion",
    "Value 3": "Engages with community",
    "Value 4": "Has Corporate Responsibility / Sustainability Board Committee",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685115920/orglogos/biogaia-logo_i4hdlb.jpg",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/biogaia-ab/",
    "Twitter": "https://twitter.com/BioGaiaBellies",
    "Blog": "https://biogaia.co.uk/pages/sustainability"
  },
  {
    "Name": "Abdi Ibrahim",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.abdiibrahim.com.tr/en",
    "Description": "We work passionately to heal the lives we touch, to be a strong global brand with innovative products and services; brave and pioneering initiatives.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685116416/orglogos/2010-award_nfmr02.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/abdiibrahim",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685219374/orglogos/abdi_gbfsmj.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/abdi-ibrahim-pharmaceuticals?originalSubdomain=tr",
    "Twitter": "https://twitter.com/abdiibrahimilac",
    "Blog": "https://www.abdiibrahim.com.tr/en/sustainability/esg"
  },
  {
    "Name": "MSD",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.msd.com/",
    "Description": "We use the power of leading-edge science to save and improve lives around the world.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449705/orglogos/msd_r17ylr.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/msd",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Invests via its Venture Impact Fund",
    "Value 3": "Published modern slavery statement",
    "Value 4": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 5": "Actively practices inclusion",
    "Value 6": "Engages with community",
    "Value 7": "Ensures pay equity",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Number of offerings": 4,
    "Linkedin": "https://twitter.com/MSDInvents",
    "Twitter": "https://www.linkedin.com/company/msd-global/",
    "Blog": "https://www.msd.com/company-overview/esg/"
  },
  {
    "Name": "Merck",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.merckgroup.com/en",
    "Description": "We believe in science and technology as a force for good.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449933/orglogos/merck_qy0edh.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/merck",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Invests via its Venture Impact Fund",
    "Value 3": "Published modern slavery statement",
    "Value 4": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 5": "Actively practices inclusion",
    "Value 6": "Engages with community",
    "Value 7": "Ensures pay equity",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685449815/orglogos/msdlogo_s187lo.png",
    "Number of offerings": 4,
    "Linkedin": "https://twitter.com/merckgroup",
    "Twitter": "https://www.linkedin.com/company/merck-group/",
    "Blog": "https://www.merckgroup.com/en/sustainability.html"
  },
  {
    "Name": "Cardinal Health",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.cardinalhealth.com/en.html",
    "Description": "We seize the opportunity to address healthcare's most complicated challenges — now, and in the future.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685449995/orglogos/cardinal_picu0g.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/cardinalhealth",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 2": "Actively practices inclusion",
    "Value 3": "Ensures pay equity",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685450052/orglogos/Cardinal-Health-Emblem_w7z6oh.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/cardinal-health/",
    "Twitter": "https://twitter.com/cardinalhealth",
    "Blog": "https://www.cardinalhealth.com/en/about-us/environmental-social-governance.html"
  },
  {
    "Name": "TLG Pharma",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://tlgcapital.com/tlg-pharma/",
    "Description": "TLG Pharma focuses on facilitating access to healthcare via innovative concepts, life-saving products, and services.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/tlgpharma",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "No ethical / environmental / social / governance / philanthropic values reported",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 1+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685453714/orglogos/tlg_sy9muy.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/tlg-pharma/",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "McKesson",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.mckesson.com/",
    "Description": "We improve care in every setting — one product, one partner, one patient at a time.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685453916/orglogos/mkesson_emqrke.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/mckesson",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 3": "Actively practices inclusion",
    "Value 4": "Engages with community",
    "Value 5": "Has Corporate Responsibility / Sustainability Board Committee",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454735/orglogos/MCK_nz2dd9.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/mckesson/",
    "Twitter": "https://twitter.com/McKesson",
    "Blog": "https://www.mckesson.com/About-McKesson/Impact/"
  },
  {
    "Name": "TCI Bio",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.tci-bio.com/",
    "Description": "TCI develops and produces functional health foods and skin care products for you and provides a global supply chain solution.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685454878/orglogos/tci_jozbwe.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/tcibio",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "No ethical / environmental / social / governance / philanthropic values reported",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685454933/orglogos/tci_jakajn.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/tci-co-ltd/",
    "Twitter": "",
    "Blog": "https://www.tci-bio.com/esgs/"
  },
  {
    "Name": "Bristol Myers Squibb",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.bms.com/",
    "Description": "Patients are our purpose. We come together each day to discover, develop and deliver transformative science to help patients prevail over serious diseases.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/bristolmyerssquibb",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472641/orglogos/bms_yio4sm.jpg",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/bristol-myers-squibb",
    "Twitter": "https://twitter.com/bmsnews",
    "Blog": "https://www.bms.com/gb/about-us/responsibility.html"
  },
  {
    "Name": "BioNTech",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.biontech.com/int/en/home.html",
    "Description": "We are a global immunotherapy powerhouse aspiring to translate science into survival.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/biontech",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 10+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472957/orglogos/BioNTech_wwy3af.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/biontech-se/",
    "Twitter": "https://twitter.com/BioNTech_Group",
    "Blog": ""
  },
  {
    "Name": "Astellas",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.astellas.com/en/",
    "Description": "We are relentless in our pursuit of scientific progress and in identifying unmet medical needs by monitoring the changes in healthcare from multiple perspectives.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/astellas",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively practices inclusion",
    "Value 4": "Engages with community",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 10+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685472822/orglogos/astellas_rdbj5r.jpg",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/astellaspharmainc/",
    "Twitter": "https://twitter.com/AstellasUS",
    "Blog": "https://www.astellas.com/en/sustainability"
  },
  {
    "Name": "Ono Pharmaceutical",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.ono-pharma.com/en",
    "Description": "Dedicated to the Fight against Disease and Pain.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/onopharmaceutical",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473594/orglogos/1200px-Ono_Pharmaceutical_company_logo.svg_yfvuxf.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/ono-pharmaceutical-co-ltd/",
    "Twitter": "https://twitter.com/onopharmafound?lang=en",
    "Blog": "https://sustainability.ono-pharma.com/en"
  },
  {
    "Name": "BASF",
    "Approved": "",
    "Category": "Chemicals",
    "URL": "https://www.basf.com/",
    "Description": "We create chemistry.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473157/orglogos/basf_bqwkuv.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/basf",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Carbon Emissions Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "Actively practices inclusion",
    "Value 5": "Engages with community",
    "Value 6": "Has Corporate Responsibility / Sustainability Board Committee",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473206/orglogos/basf2_vyvvok.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/basf/",
    "Twitter": "https://twitter.com/BASF",
    "Blog": "https://www.basf.com/global/en/who-we-are/sustainability.html"
  },
  {
    "Name": "Menzies Aviation",
    "Approved": "",
    "Category": "Transport / Logistics",
    "URL": "https://menziesaviation.com/",
    "Description": "We provide safe and efficient aviation services, for every customer, every time.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/menziesaviation",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685473937/orglogos/menzies-aviation-seeklogo.com_jwiscr.svg",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/menzies-aviation/",
    "Twitter": "",
    "Blog": "https://menziesaviation.com/about-overview/corporate-responsibility-and-sustainability/"
  },
  {
    "Name": "Aramex",
    "Approved": "",
    "Category": "Transport / Logistics",
    "URL": "https://www.aramex.com/",
    "Description": "Delivering on your business promises everyday.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/aramex",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Carbon Emissions Target",
    "Value 2": "Set Carbon Neutrality Target",
    "Value 3": "Published modern slavery statement",
    "Value 4": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 5": "All members of key board committees are independent",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474054/orglogos/unnamed_etzvo8.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/aramex/",
    "Twitter": "https://twitter.com/aramex",
    "Blog": "https://www.aramex.com/us/en/aramex-2022-annual-report/sustainability"
  },
  {
    "Name": "Agility Logistics",
    "Approved": "",
    "Category": "Transport / Logistics",
    "URL": "https://www.agility.com/en/",
    "Description": "The values at the heart of Agility’s culture are integrity, personal ownership, teamwork and excellence.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/agilitylogistics",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Published modern slavery statement",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Asia HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685474674/orglogos/agility_fdkanr.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/agility/",
    "Twitter": "https://twitter.com/agility",
    "Blog": "https://sustainability.agility.com/"
  },
  {
    "Name": "Farmaceutici Procemsa",
    "Approved": "",
    "Category": "Medical Devices",
    "URL": "https://www.procemsa.it/en/",
    "Description": "Our work is driven by the desire to respond to the needs of our customers.\nIt is passion, it is fantasy, it is discovery, it is Made in Italy.\nIt is the wish to always do better after years of experience in these fields.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475126/orglogos/procemsa_nhwtik.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/farmaceuticiprocemsa",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "No ethical / environmental / social / governance / philanthropic values reported",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685475408/orglogos/procemsa_zb3d7t.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/farmaceutici-procemsa-s.p.a./?originalSubdomain=it",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "Guerbet",
    "Approved": "",
    "Category": "Medical Devices",
    "URL": "https://www.guerbet.com/",
    "Description": "At Guerbet, we build lasting relationships so that we enable people to live better. This is our Purpose.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685475560/orglogos/guerbet_ytsk7j.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/guerbet",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "No ethical / environmental / social / governance / philanthropic values reported",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476020/orglogos/nice_otsoi1.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/guerbet/",
    "Twitter": "https://twitter.com/GuerbetGroup/",
    "Blog": "https://www.guerbet.com/our-group/our-responsibility"
  },
  {
    "Name": "PerkinElmer",
    "Approved": "",
    "Category": "Diagnostics",
    "URL": "https://www.perkinelmer.com/",
    "Description": "We push the boundaries of science to help our customers measure, detect, and report in ways that help make their products better. Our science has a clear purpose—to help our customers achieve theirs.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476317/orglogos/pe_bqhxt1.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/perkinelmer",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Published modern slavery statement",
    "Value 3": "Actively practices inclusion",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476306/orglogos/pel_dmpnkw.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/perkinelmer/",
    "Twitter": "https://twitter.com/PerkinElmer",
    "Blog": ""
  },
  {
    "Name": "QIAGEN",
    "Approved": "",
    "Category": "Diagnostics",
    "URL": "https://www.qiagen.com/",
    "Description": "Making improvements in life possible.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476439/orglogos/qiagen_knp3qa.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/qiagen",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Net-Zero Carbon Emissions Target",
    "Value 2": "Set Carbon Neutrality Target",
    "Value 3": "Published modern slavery statement",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 30+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476940/orglogos/qiqi_ehvw12.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/qiagen/",
    "Twitter": "https://twitter.com/QIAGEN",
    "Blog": "https://www.qiagen.com/us/sustainability"
  },
  {
    "Name": "bioMérieux",
    "Approved": "",
    "Category": "Diagnostics",
    "URL": "https://www.biomerieux.com/",
    "Description": "We provide diagnostic solutions which determine the source of disease & contamination to improve patient health & ensure consumer safety.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476522/orglogos/bio_dvgbbe.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/biomerieux",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Published modern slavery statement",
    "Value 2": "Actively practices inclusion",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 50+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685477005/orglogos/bio_mab0d3.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/biomerieux/?originalSubdomain=uk",
    "Twitter": "https://twitter.com/biomerieux",
    "Blog": "https://www.biomerieux.com/corp/en/our-responsibility.html"
  },
  {
    "Name": "Illumina",
    "Approved": "",
    "Category": "Non-Medical Equipment",
    "URL": "https://www.illumina.com/",
    "Description": "Our mission is to improve human health by unlocking the power of the genome. We believe that, together, we can use genomics to build a stronger world for the greater good.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu_a3yski.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/illumina",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Set Carbon Neutrality Target",
    "Value 2": "Set Net-Zero Greenhouse-Gas Emissions Target",
    "Value 3": "Actively practices inclusion",
    "Value 4": "Ensures pay equity",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 10+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "North America HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1685476645/orglogos/illu2_pisstl.png",
    "Number of offerings": 4,
    "Linkedin": "https://www.linkedin.com/company/illumina/",
    "Twitter": "https://twitter.com/illumina",
    "Blog": "https://www.illumina.com/company/about-us/corporate-social-responsibility/environmental-social-governance.html"
  },
  {
    "Name": "Leo Pharma",
    "Approved": "",
    "Category": "Pharmaceuticals",
    "URL": "https://www.leo-pharma.co.uk/",
    "Description": "We are a global company dedicated to advancing the standard of care for the benefit of people with skin conditions, their families and society.",
    "Discount": "",
    "Does it drive you into a paid account": "Selling and Donating",
    "Requirement": "",
    "Favicon": "https://res.cloudinary.com/imagehosted/image/upload/v1692116951/orglogos/leologo_rinpzq.png",
    "How to apply (text)": "",
    "How to apply (URL or email)": "/org/leopharma",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "Published modern slavery statement",
    "Value 2": "Actively manages ethical and sustainability standards in its supply chain",
    "Value 3": "Actively practices inclusion",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "Trading for 100+ years",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "Europe HQ",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "https://res.cloudinary.com/imagehosted/image/upload/v1692118022/orglogos/leobiglogo_xnn4oy.png",
    "Number of offerings": 6,
    "Linkedin": "https://www.linkedin.com/company/leo-pharma",
    "Twitter": "https://twitter.com/LEOHealthySkin",
    "Blog": "https://www.leo-pharma.com/our-responsibility"
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  },
  {
    "Name": "",
    "Approved": "",
    "Category": "",
    "URL": "",
    "Description": "",
    "Discount": "",
    "Does it drive you into a paid account": "",
    "Requirement": "",
    "Favicon": "",
    "How to apply (text)": "",
    "How to apply (URL or email)": "",
    "More info URL": "",
    "Category 2": "",
    "Category 3": "",
    "Category 4": "",
    "Category 5": "",
    "Category 6": "",
    "Category 7": "",
    "Category 8": "",
    "Category 9": "",
    "Category 10": "",
    "Category 11": "",
    "Category 12": "",
    "Category 13": "",
    "Category 14": "",
    "Category 15": "",
    "Category 16": "",
    "Category 17": "",
    "Category 18": "",
    "Category 19": "",
    "Category 20": "",
    "Value 1": "",
    "Value 2": "",
    "Value 3": "",
    "Value 4": "",
    "Value 5": "",
    "Value 6": "",
    "Value 7": "",
    "Value 8": "",
    "Property 1": "",
    "Property 2": "",
    "Property 3": "",
    "Property 4": "",
    "Property 5": "",
    "Property 6": "",
    "Property 7": "",
    "Property 8": "",
    "Location 1": "",
    "Location 2": "",
    "Location 3": "",
    "Location 4": "",
    "Location 5": "",
    "Location 6": "",
    "Location 7": "",
    "Location 8": "",
    "Logo URL": "",
    "Number of offerings": 0,
    "Linkedin": "",
    "Twitter": "",
    "Blog": ""
  }
]